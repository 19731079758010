import React, { Component } from 'react';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { Modal, ModalBody, ModalFooter, Fade } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  answerCallRequest,
  declineCallRequest,
  hangupCallRequest,
  hangupHoldCallRequest,
  fetchInterlocutorDataRequest,
  hangupChannelRequest,
  setNoValidJsepCall,
  reactivateWebrtcPhone,
  traceWebrtcTrouble,
} from '../../../js/phone/actions';
import {
  hasGrants,
  getMeAllCalls,
  getMeActiveCalls,
  getMeConferenceId,
  getMeId,
  isActiveAssistedTransfer,
  otherSessions,
  hasRecordedCurrentCall,
} from '../../../js/me/selectors';
import {
  isWebrtcActive,
  getSupplier,
  isHoldCallAvailable,
  isAssistedTransferAvailable,
  isLinkCallsAvailable,
  isSwitchCallsAvailable,
  isMuteCallAvailable,
  isRecordCallAvailable,
  getParkPrefix,
  isPhoneMuted,
  getSearchedNumber,
  isConferenceAvailable,
  getMeWebrtcExtensionNumber,
  getMeMainExtensionNumber,
  isNumberAnExtension,
  getIncomingCallJsep,
  isWebrtcInUse,
  isValidJsep,
  isWebphoneReactivating,
} from '../../../js/phone/selectors';
import {
  isCallNotificationEnabled,
  getPlatformVolume,
} from '../../../js/settings/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { PbxSettings } from '../../../js/phone/PbxSettingsUtils';
import Button from '../../../components/formControls/Button';
import Icon from '../../../components/icons/Icon';
import CallToolbar from './CallToolbar';
import InterlocutorPanel from '../../../components/InterlocutorPanel';
import { NotificationSettings } from '../../../js/notifications/NotificationUtils';
import Timer from '../../../components/Timer';
import { refreshMeCalls } from '../../../js/cdr/actions';
import {
  getPhoneExternalUrlsAnswered,
  getPhoneExternalUrlsHangup,
  getPhoneExternalUrlsIncoming,
} from '../../../js/externalUrl/selectors';
import { ExternalUrlUtils } from '../../../js/externalUrl/ExternalUrlUtils';
import axios from 'axios';

const messages = defineMessages({
  holdCall: {
    id: 'CallToolbar.title.holdCall',
    defaultMessage: 'Call hold',
  },
  incomingCall: {
    id: 'CallToolbar.title.incomingCall',
    defaultMessage: 'Call incoming',
  },
  outgoingCall: {
    id: 'CallToolbar.title.outgoingCall',
    defaultMessage: 'Call outgoing',
  },
  parkedCall: {
    id: 'CallToolbar.title.parkedCall',
    defaultMessage: 'Call parked',
  },
  callback: {
    id: 'CallToolbar.title.callback',
    defaultMessage: 'Call starting',
  },
  answer: {
    id: 'CallToolbar.answer',
    defaultMessage: 'Answer',
  },
  hangup: {
    id: 'CallToolbar.hangup',
    defaultMessage: 'Hang up',
  },
  transfer: {
    id: 'CallToolbar.transfer',
    defaultMessage: 'Transfer',
  },
  cancelButton: {
    id: 'CallToolbar.cancelButton',
    defaultMessage: 'Cancel',
  },
  webphoneProblems: {
    id: 'CallToolbar.webphoneProblems',
    defaultMessage: 'Oh oh! There are problems with webphone',
  },
  tryReactivate: {
    id: 'CallToolbar.tryReactivate',
    defaultMessage: 'Restart webphone',
  },
});

class CallPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callDuration: 0,
      audioIn: null,
      audioOut: null,
      noJSEPModalOpen: false,
      callIncomingUrlId: null,
    };
    this.modalTimeout = null;
  }

  componentDidMount() {
    if (this.props.call) {
      if (!this.props.call.calledData || !this.props.call.callingData) {
        this.props.fetchInterlocutor({
          data: this.props.call,
          context: 'calls',
        });
      }
    }
    this.setState({ callDuration: this.duration() });
  }

  componentDidUpdate(prevprops) {
    if (this.props.call && !isEqual(prevprops.call, this.props.call)) {
      if (
        this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
        this.state.callIncomingUrlId !== this.props.call.callid &&
        this.props.call.callingData &&
        this.props.call.destChannel &&
        this.props.call.direction === PhoneEnums.Direction.In
      ) {
        this.setState({ callIncomingUrlId: this.props.call.callid });
        this.externalUrlAction(ExternalUrlUtils.Event.INCOMING);
      }
      if (
        this.props.call.status === PhoneEnums.CallsStatuses.active &&
        this.props.call.direction === PhoneEnums.Direction.In &&
        prevprops.call.status
      ) {
        this.externalUrlAction(ExternalUrlUtils.Event.ANSWERED);
      }
      if (
        !this.props.call.calledData ||
        !this.props.call.callingData ||
        // this condition has been made to retrieve callingData for incoming calls on extensions that receives CALLING status and then ALERTING status (ticket 4202)
        (prevprops.call.status === PhoneEnums.CallsStatuses.calling &&
          this.props.call.status === PhoneEnums.CallsStatuses.alerting)
      ) {
        this.props.fetchInterlocutor({
          data: this.props.call,
          context: 'calls',
        });
      }
      if (
        /* WE RESTART THE TIMER FOR HOLD CALLS 
        this.props.call.status !== PhoneEnums.CallsStatuses.hold &&
        */
        this.props.call.status !== PhoneEnums.CallsStatuses.active
      ) {
        this.setState({ callDuration: 0 });
      }
      if (this.props.call.status === PhoneEnums.CallsStatuses.alerting) {
        if (
          (this.props.supplier === PhoneEnums.PbxType.NETHESIS &&
            this.props.call.direction === PhoneEnums.Direction.In) ||
          (this.props.supplier === PhoneEnums.PbxType.ABILIS &&
            this.props.call.direction === PhoneEnums.Direction.Out)
        ) {
          this.playAudioIn();
        }
      }
      if (
        this.props.call.status === PhoneEnums.CallsStatuses.active &&
        prevprops.call.status !== PhoneEnums.CallsStatuses.active
      ) {
        this.setState({ callDuration: 0 });
        this.pauseAudioIn();
      }
    }
    if (prevprops.jsep && !this.props.jsep) {
      this.pauseAudioIn();
    }
    if (
      !this.props.validJsep &&
      this.props.webrtcUsing &&
      this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
      this.props.call.direction === PhoneEnums.Direction.In &&
      !this.state.noJSEPModalOpen &&
      !this.props.webPhoneReactivating
    ) {
      this.modalTimeout = setTimeout(() => {
        this.setState({ noJSEPModalOpen: true });
      }, 500);
    }
    if (this.props.validJsep && this.modalTimeout) {
      clearTimeout(this.modalTimeout);
      if (this.state.noJSEPModalOpen) this.setState({ noJSEPModalOpen: false });
    }
  }

  componentWillUnmount() {
    if (
      this.props.call &&
      this.props.call.direction === PhoneEnums.Direction.In &&
      this.props.call.status === PhoneEnums.CallsStatuses.active
    ) {
      this.externalUrlAction(ExternalUrlUtils.Event.HANGUP);
    }
    // this.props.refreshCallsLogs();
    if (this.state.audioIn) {
      this.state.audioIn.pause();
    }
    if (this.state.audioOut) {
      this.state.audioOut.pause();
    }
  }

  externalUrlAction = (event) => {
    const actions =
      event === ExternalUrlUtils.Event.INCOMING
        ? this.props.externalUrlIncoming
        : event === ExternalUrlUtils.Event.ANSWERED
        ? this.props.externalUrlAnswered
        : this.props.externalUrlHangup;
    actions.forEach((item) => {
      if (
        (item.constraint === ExternalUrlUtils.Constraint.EXTERNAL &&
          this.props.call.callingData.interlocutorType === 'U') ||
        (item.constraint === ExternalUrlUtils.Constraint.EXTENSION &&
          this.props.call.callingData.interlocutorType !== 'U')
      ) {
        return;
      }
      let url = item.link
        .replace('[CALLER]', this.props.call.calling)
        .replace(
          '[CALLERNAME]',
          this.props.call.callingData
            ? this.props.call.callingData.fullname
            : ''
        )
        .replace('[EXT]', this.props.call.called)
        .replace('[CALLID]', this.props.call.linkedId)
        .replace('[DURATION]', this.duration())
        .replace(
          '[CLIENTNUMBER]',
          this.props.call.callingData
            ? this.props.call.callingData.clientNumber || ''
            : ''
        );
      const datePlaceholderTmp =
        url.indexOf('[DATE') >= 0
          ? url.substring(url.indexOf('[DATE'))
          : null;
      let datePlaceholder = null;
      if (datePlaceholderTmp) {
        datePlaceholder = datePlaceholderTmp.substring(
          0,
          datePlaceholderTmp.indexOf(']') + 1
        );
      }
      if (datePlaceholder) {
        url = url.replace(
          `${datePlaceholder}`,
          moment(this.props.call.datetime).format(
            datePlaceholder
              .substring(
                datePlaceholder.indexOf(',') + 1,
                datePlaceholder.indexOf(']')
              )
              .replace(' ', '%20')
          )
        );
      }
      url = url.indexOf('https://') < 0 ? 'https://' + url : url;
      if (item.action === ExternalUrlUtils.Action.LINK) {
        window.open(url);
      } else if (item.action === ExternalUrlUtils.Action.API) {
        axios.get(url);
      }
    });
  };

  playAudioIn = () => {
    if (this.props.webPhoneReactivating) return;
    if (!this.state.audioIn) {
      const audioIn = new Audio(PbxSettings.RING_SOUND);
      audioIn.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audioIn.loop = true;
      audioIn.play();
      this.setState({ audioIn });
    }
  };

  pauseAudioIn = () => {
    if (this.state.audioIn) {
      this.state.audioIn.pause();
      this.setState({ audioIn: null });
    }
  };

  playAudioOut = () => {
    if (!this.state.audioOut) {
      const audioOut = new Audio(PbxSettings.AVAILABLE_SOUND);
      audioOut.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audioOut.loop = true;
      audioOut.play();
      this.setState({ audioOut });
    }
  };

  pauseAudioOut = () => {
    if (this.state.audioOut) {
      this.state.audioOut.pause();
      this.setState({ audioOut: null });
    }
  };

  toggleNoJSEPModal = () => {
    this.setState({ noJSEPModalOpen: !this.state.noJSEPModalOpen });
  };

  handleReActivateWebphone = () => {
    this.props.reactivateWebphone();
    this.props.traceWebrtc({ context: 'No valid JSEP' });
    this.toggleNoJSEPModal();
    this.pauseAudioIn();
  };

  setToolbarButtons = () => {
    const buttons = {
      record: false,
      recording: false,
      toggleMute: false,
      hold: false,
      unhold: false,
      park: false,
      blindTransfer: false,
      aidTransfer: false,
      reverse: false,
      link: false,
      startConference: false,
      addToConference: false,
    };
    if (this.props.call.status === PhoneEnums.CallsStatuses.callback)
      return buttons;
    if (
      this.props.call.status === PhoneEnums.CallsStatuses.active &&
      this.props.meActiveCallsQuantity === 1
    ) {
      if (
        this.props.isRecordCallAvailable &&
        this.props.canRecord &&
        !this.props.call.recording &&
        !this.props.isInconference &&
        !this.props.hasRecordedCurrentCall
      ) {
        buttons.record = true;
      }
      if (this.props.call.recording) {
        buttons.recording = true;
      }
      if (this.props.isMuteCallAvailable && !this.props.isInconference) {
        buttons.toggleMute = true;
      }
      if (
        this.props.isHoldCallAvailable &&
        !this.props.call.recording &&
        !this.props.isInconference
      ) {
        buttons.hold = true;
      }
      if (
        !this.props.call.recording &&
        !this.props.isInconference &&
        this.props.meCallsQuantity < 2
      ) {
        buttons.park = true;
        buttons.aidTransfer = true;
      }
      if (
        this.props.isConferenceAvailable &&
        // this.props.webrtcActive !== 'ON' &&
        !this.props.call.recording &&
        !this.props.isInconference
      ) {
        buttons.startConference = true;
      }
      if (this.props.isInconference) {
        buttons.addToConference = true;
      }
    }
    if (
      this.props.call.status === PhoneEnums.CallsStatuses.active &&
      this.props.meActiveCallsQuantity > 1
    ) {
      if (this.props.isSwitchCallsAvailable) {
        buttons.reverse = true;
      }
      if (this.props.isLinkCallsAvailable) {
        buttons.link = true;
      }
    }
    // NB: with nethesis we cannot transfer a call before we answer
    if (
      (this.props.call.status === PhoneEnums.CallsStatuses.active ||
        (this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
          this.props.supplier === PhoneEnums.PbxType.ABILIS &&
          this.props.call.direction === PhoneEnums.Direction.Out)) &&
      !this.props.call.recording &&
      !this.props.isInconference &&
      this.props.meCallsQuantity < 2
    ) {
      buttons.blindTransfer = true;
    }
    if (
      this.props.supplier === PhoneEnums.PbxType.ABILIS &&
      this.props.isHoldCallAvailable &&
      this.props.call.status === PhoneEnums.CallsStatuses.hold &&
      !this.props.call.recording &&
      !this.props.isInconference
    ) {
      if (this.props.meCallsQuantity === 1) {
        buttons.unhold = true;
      }
    }
    return buttons;
  };

  setTitle = () => {
    if (this.props.call.status === PhoneEnums.CallsStatuses.callback) {
      return this.props.intl.formatMessage(messages.callback);
    }
    if (this.props.call.status === PhoneEnums.CallsStatuses.hold) {
      return this.props.intl.formatMessage(messages.holdCall);
    }
    if (
      this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
      this.props.supplier === PhoneEnums.PbxType.NETHESIS &&
      this.props.call.called === this.props.call.calling
    ) {
      return this.props.intl.formatMessage(messages.incomingCall);
    }
    if (
      this.props.call.status === PhoneEnums.CallsStatuses.alerting ||
      this.props.call.status === PhoneEnums.CallsStatuses.active ||
      this.props.call.status === PhoneEnums.CallsStatuses.calling
    ) {
      if (
        (this.props.supplier === PhoneEnums.PbxType.NETHESIS &&
          this.props.call.direction === PhoneEnums.Direction.Out) ||
        (this.props.supplier === PhoneEnums.PbxType.ABILIS &&
          this.props.call.direction === PhoneEnums.Direction.In)
      ) {
        return this.props.intl.formatMessage(messages.outgoingCall);
      }
      if (
        (this.props.supplier === PhoneEnums.PbxType.NETHESIS &&
          this.props.call.direction === PhoneEnums.Direction.In) ||
        (this.props.supplier === PhoneEnums.PbxType.ABILIS &&
          this.props.call.direction === PhoneEnums.Direction.Out)
      ) {
        return this.props.intl.formatMessage(messages.incomingCall);
      }
    }
    if (this.props.call.status === PhoneEnums.UserStatuses.parked) {
      return this.props.intl.formatMessage(messages.parkedCall);
    }

    return this.props.call.status;
  };

  duration = () => {
    const start = this.props.call.timestamp
      ? moment(this.props.call.timestamp).valueOf()
      : moment().valueOf();
    const now = moment().valueOf();
    return Math.floor((now - start) / 1000);
  };

  handleClose = () => {
    return this.props.call &&
      this.props.call.status === PhoneEnums.CallsStatuses.hold &&
      this.props.meActiveCallsQuantity > 1
      ? this.props.hangupHold(this.props.call.callid)
      : this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
        this.props.call.direction === PhoneEnums.Direction.In
      ? this.props.decline(this.props.call.callid)
      : this.props.hangup(this.props.call.callid);
  };

  handleCancelAid = () => {
    return (
      this.props.call && this.props.hangupChannel(this.props.call.destChannel)
    );
  };

  handleAnswer = () => {
    return this.props.call && this.props.answer(this.props.call.callid);
  };

  render() {
    const {
      activeAssistedTransfer,
      webrtcActive,
      otherSessionsNumber,
      webrtcUsing,
      webPhoneReactivating,
      intl: { formatMessage },
    } = this.props;

    // abilis call direction is the opposite of the nethesis one
    const interlocutor = this.props.call
      ? (this.props.call.direction === PhoneEnums.Direction.In &&
          this.props.supplier === PhoneEnums.PbxType.ABILIS) ||
        (this.props.call.direction === PhoneEnums.Direction.Out &&
          this.props.supplier === PhoneEnums.PbxType.NETHESIS)
        ? this.props.call.calledData
        : this.props.call.callingData
      : null;
    const showToolbar =
      Object.keys(this.setToolbarButtons()).filter(
        (k) => this.setToolbarButtons()[k] === true
      ).length > 0;
    const showAnswerButton =
      this.props.webrtcActive === 'ON' &&
      this.props.call.status === PhoneEnums.CallsStatuses.alerting &&
      this.props.call.direction === PhoneEnums.Direction.In;
    const showHangupButton = true; // !(this.props.supplier === PhoneEnums.PbxType.NETHESIS)
    const users = [
      this.props.call &&
        this.props.call.calledData &&
        this.props.call.calledData.id,
      this.props.call &&
        this.props.call.callingData &&
        this.props.call.callingData.id,
    ];
    const isSameDevice =
      this.props.call &&
      (!this.props.call.number ||
        this.props.call.number ===
          (this.props.webrtcActive === 'ON'
            ? this.props.webrtcNumber
            : this.props.mainNumber));
    const isCallback =
      this.props.call.status === PhoneEnums.CallsStatuses.callback;

    const webrtcOtherSessionInUse =
      webrtcActive === 'ON' && otherSessionsNumber > 0 && !webrtcUsing;

    return this.props.call &&
      isSameDevice &&
      !webrtcOtherSessionInUse &&
      !webPhoneReactivating &&
      this.props.call.status !== PhoneEnums.CallsStatuses.offhook ? (
      <div id="CallPanel">
        <Fade className="card mt-2">
          <div className="card-header text-left small p-1">
            <div className="row no-gutters">
              <div className="col-7">{this.setTitle()}</div>
              {!isCallback && (
                <div className="col-5 text-right">
                  {(this.props.call.direction === PhoneEnums.Direction.Out &&
                    this.props.supplier === PhoneEnums.PbxType.ABILIS) ||
                  (this.props.call.direction === PhoneEnums.Direction.In &&
                    this.props.supplier === PhoneEnums.PbxType.NETHESIS) ? (
                    <Icon
                      name="arrow-double-left"
                      width={16}
                      height={16}
                      style={{ top: '3px' }}
                    />
                  ) : (
                    <Icon
                      name="arrow-double-right"
                      width={16}
                      height={16}
                      style={{ top: '3px' }}
                    />
                  )}
                  <span className="pl-1">
                    {' '}
                    <Timer time={this.state.callDuration} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="card-body p-1 text-left small p-1">
            {interlocutor ? (
              <InterlocutorPanel
                ellipsis
                interlocutor={interlocutor}
                addContactButton
                callingContactDetails
                fromQueueName={this.props.call.fromQueueName}
                showExternalUrl
                extension={
                  (this.props.call.direction === PhoneEnums.Direction.In &&
                    this.props.supplier === PhoneEnums.PbxType.ABILIS) ||
                  (this.props.call.direction === PhoneEnums.Direction.Out &&
                    this.props.supplier === PhoneEnums.PbxType.NETHESIS)
                    ? this.props.call.calling
                    : this.props.call.called
                }
                callId={this.props.call.callid}
                callDate={this.props.call.datetime}
              />
            ) : (
              '...'
            )}
          </div>
          <div className="card-body text-center p-0 border-top">
            {showToolbar && (
              <CallToolbar
                callId={this.props.call.callid}
                callStatus={this.props.call.status}
                interlocutor={interlocutor && interlocutor.number}
                direction={this.props.call.direction}
                called={this.props.call.called}
                calling={this.props.call.calling}
                users={users}
                target="CallPanel"
                buttons={this.setToolbarButtons()}
              />
            )}
          </div>
          <div className="card-body p-1">
            <div className="btn-group btn-block">
              {activeAssistedTransfer &&
              this.props.supplier === PhoneEnums.PbxType.NETHESIS ? (
                <>
                  <Button
                    text={formatMessage(messages.cancelButton)}
                    className="btn-danger"
                    style={{ width: '50%' }}
                    onClick={this.handleCancelAid}
                  />
                  <Button
                    text={formatMessage(messages.transfer)}
                    className="btn-success"
                    style={{ width: '50%' }}
                    onClick={this.handleClose}
                  />
                </>
              ) : (
                <>
                  {showAnswerButton && (
                    <Button
                      text={formatMessage(messages.answer)}
                      className="btn-success"
                      style={{ width: '50%' }}
                      onClick={this.handleAnswer}
                    />
                  )}
                  {showHangupButton && (
                    <Button
                      text={formatMessage(messages.hangup)}
                      className="btn-danger"
                      style={{ width: showAnswerButton ? '50%' : '100%' }}
                      onClick={this.handleClose}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Fade>
        <Modal
          isOpen={this.state.noJSEPModalOpen}
          toggle={this.toggleNoJSEPModal}
        >
          <ModalBody>
            <div>{formatMessage(messages.webphoneProblems)}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.handleReActivateWebphone}
              text={formatMessage(messages.tryReactivate)}
            />
            <button
              type="button"
              className="btn btn-outline-primary ml-2"
              onClick={this.toggleNoJSEPModal}
            >
              {formatMessage(messages.cancelButton)}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    ) : (
      <div />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    meId: getMeId(state),
    webrtcActive: isWebrtcActive(state),
    meCallsQuantity: getMeAllCalls(state).length,
    meActiveCallsQuantity: getMeActiveCalls(state).length,
    supplier: getSupplier(state),
    number: getSearchedNumber(state, 'CallPanel'),
    isHoldCallAvailable: isHoldCallAvailable(state),
    isAssistedTransferAvailable: isAssistedTransferAvailable(state),
    isLinkCallsAvailable: isLinkCallsAvailable(state),
    isSwitchCallsAvailable: isSwitchCallsAvailable(state),
    isMuteCallAvailable: isMuteCallAvailable(state),
    isRecordCallAvailable: isRecordCallAvailable(state),
    hasRecordedCurrentCall: hasRecordedCurrentCall(state),
    isConferenceAvailable: isConferenceAvailable(state),
    parkPrefix: getParkPrefix(state),
    muted: isPhoneMuted(state),
    canRecord: hasGrants(state, 'PBX_REC'),
    isInconference: !!getMeConferenceId(state),
    isCallNotificationEnabled: isCallNotificationEnabled(state),
    platformVolume: getPlatformVolume(state),
    webrtcNumber: getMeWebrtcExtensionNumber(state),
    mainNumber: getMeMainExtensionNumber(state),
    isCalledAnExtension:
      ownProps.call &&
      ownProps.call.called &&
      isNumberAnExtension(state, ownProps.call.called),
    activeAssistedTransfer: isActiveAssistedTransfer(state),
    jsep: getIncomingCallJsep(state),
    otherSessionsNumber: otherSessions(state),
    webrtcUsing: isWebrtcInUse(state),
    validJsep: isValidJsep(state),
    webPhoneReactivating: isWebphoneReactivating(state),
    externalUrlIncoming: getPhoneExternalUrlsIncoming(state),
    externalUrlAnswered: getPhoneExternalUrlsAnswered(state),
    externalUrlHangup: getPhoneExternalUrlsHangup(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    answer: answerCallRequest,
    decline: declineCallRequest,
    hangup: hangupCallRequest,
    hangupHold: hangupHoldCallRequest,
    fetchInterlocutor: fetchInterlocutorDataRequest,
    hangupChannel: hangupChannelRequest,
    setNoValidWebrtcCall: setNoValidJsepCall,
    reactivateWebphone: reactivateWebrtcPhone,
    refreshCallsLogs: refreshMeCalls,
    traceWebrtc: traceWebrtcTrouble,
  })(CallPanel)
);
