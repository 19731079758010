import moment from 'moment';
import { userPresenceChange } from './actions';
import {
  sendSubscriptionSuccess,
  sendSubscriptionFailure,
} from '../websocket/actions';
import {
  sendStopUseWebphoneSuccess,
  stopUseWebphone,
  sendStopUseWebphoneFailure,
} from '../phone/actions';

export class PresenceEnums {
  static Status = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
  };

  static Device = {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
  };

  static PresenceTopics = {
    PRESENCE_STATUS: 'PRESENCE_STATUS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    WEBPHONE: 'WEBPHONE',
  };
}

const managePresenceStatusEvent = (data) => {
  return {
    action: userPresenceChange,
    data: {
      ...data.payload,
      init: data.recipient != null,
    },
  };
};

const manageSubscriptionEvent = (data) => {
  if (data.payload.success) {
    return {
      action: sendSubscriptionSuccess,
    };
  }
  return {
    action: sendSubscriptionFailure,
  };
};

const manageWebphoneEvent = (data) => {
  if (data.action && data.action === 'ACK' && data.payload) {
    if (data.payload.allow) {
      return {
        // the action is dispatched in phone/sagas.js sendStopUseWebrtcPhone
        action: sendStopUseWebphoneSuccess,
      };
    }
    return {
      action: sendStopUseWebphoneFailure,
    };
  }
  return {
    action: stopUseWebphone,
    data: { ...data.payload, senderUid: data.senderUid },
  };
};

export const managePresenceEvent = (data) => {
  const { topic } = data;
  switch (topic) {
    case PresenceEnums.PresenceTopics.PRESENCE_STATUS:
      return managePresenceStatusEvent(data);
    case PresenceEnums.PresenceTopics.SUBSCRIPTION:
      return manageSubscriptionEvent(data);
    case PresenceEnums.PresenceTopics.WEBPHONE:
      return manageWebphoneEvent(data);
    default:
      return null;
  }
};

export const retrievePresencesPlanHeaderDates = (params, vacationPlan) => {
  let start = moment(params.start).toDate();
  const end = moment(params.end).toDate();
  const headers = [];
  const headerDates = [];
  let year;
  let currentMonth;
  while (start <= end) {
    const currentYear = start.getFullYear();
    if (year) {
      if (year.value !== currentYear) {
        headers.push(year);
        year = {
          total: 0,
          value: currentYear,
          months: [],
        };
      }
    } else {
      year = {
        total: 0,
        value: currentYear,
        months: [],
      };
    }
    year.total += 1;
    currentMonth = start.getMonth();
    const currentDay = start.getDate();
    if (
      !year.months.length ||
      year.months[year.months.length - 1].value !== currentMonth
    ) {
      year.months.push({
        value: currentMonth,
        days: [currentDay],
        sundays: [],
      });
    } else {
      year.months[year.months.length - 1].days.push(currentDay);
    }
    if (
      !vacationPlan &&
      start.getDay() === 0 &&
      moment(start).add(1, 'days').toDate() <= end
    ) {
      year.months[year.months.length - 1].sundays.push(currentDay);
      year.total += 1;
    }
    headerDates.push(start);
    start = moment(start).add(1, 'days').toDate();
  }
  if (year) {
    year.total += 1;
    headers.push(year);
  }
  return {
    headers,
    headerDates,
  };
};
