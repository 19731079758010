import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import UserAvatar from './UserAvatar';
import ContactAvatar from './ContactAvatar';
import InterlocutorCallToolbar from './InterlocutorCallToolbar';
import InterlocutorConferenceToolbar from './InterlocutorConferenceToolbar';
import { PhoneEnums } from '../js/phone/PhoneUtils';
import AddContactButton from './buttons/AddContactButton';
import Icon from './icons/Icon';
import { addressbookGrant } from '../js/me/selectors';
import { getPhoneExternalUrlsAlways } from '../js/externalUrl/selectors';
import { ExternalUrlUtils } from '../js/externalUrl/ExternalUrlUtils';

const messages = defineMessages({
  placeholder: {
    id: 'InterlocutorPanel.placeholder',
    defaultMessage: 'Search by name or number',
  },
  externalUrlButton: {
    id: 'InterlocutorPanel.tooltip.externalUrlButton',
    defaultMessage: 'Open external url',
  },
  infoButton: {
    id: 'InterlocutorPanel.tooltip.infoButton',
    defaultMessage: 'Info',
  },
  noOptionsMessage: {
    id: 'InterlocutorPanel.noOptionsMessage',
    defaultMessage: 'No users found',
  },
  MOBILE: {
    id: 'InterlocutorPanel.label.MOBILE',
    defaultMessage: 'Mobile: ',
  },
  OFFICE: {
    id: 'InterlocutorPanel.label.OFFICE',
    defaultMessage: 'Office: ',
  },
  HOME: {
    id: 'InterlocutorPanel.label.HOME',
    defaultMessage: 'Home: ',
  },
  FAX: {
    id: 'InterlocutorPanel.label.FAX',
    defaultMessage: 'Fax: ',
  },
  OTHERS: {
    id: 'InterlocutorPanel.label.OTHERS',
    defaultMessage: 'Others: ',
  },
  EXTENSION: {
    id: 'InterlocutorPanel.label.extension',
    defaultMessage: 'Extension: ',
  },
  externalNumber: {
    id: 'InterlocutorPanel.externalNumber',
    defaultMessage: 'External number',
  },
});

const style = {
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width: '83%',
  },
};

const InterlocutorPanel = ({
  contactGrant,
  interlocutor,
  align,
  callToolbar,
  confToolbar,
  conference,
  addContactButton,
  ellipsis,
  avatarPopover,
  onPickup,
  onJoin,
  onSpy,
  onIntrude,
  onHangup,
  fromQueueName,
  externalUrlAlways,
  showExternalUrl,
  extension,
  callId,
  callDate,
  intl: { formatMessage },
}) => {
  const number =
    interlocutor && interlocutor.number
      ? interlocutor.number !== '<unknown>'
        ? interlocutor.number
        : formatMessage(messages.externalNumber)
      : '...';

  let externalUrl;
  if (showExternalUrl && externalUrlAlways && externalUrlAlways.length) {
    if (
      !(
        (externalUrlAlways[0].constraint ===
          ExternalUrlUtils.Constraint.EXTERNAL &&
          interlocutor.interlocutorType === 'U') ||
        (externalUrlAlways[0].constraint ===
          ExternalUrlUtils.Constraint.EXTENSION &&
          interlocutor.interlocutorType !== 'U')
      )
    ) {
      externalUrl = externalUrlAlways[0].link
        .replace('[CALLER]', interlocutor.number)
        .replace('[CALLERNAME]', interlocutor.fullname || '')
        .replace('[EXTENSION]', extension)
        .replace('[CALLID]', callId)
        .replace('[CLIENTNUMBER]', interlocutor.clientNumber || '');
      const datePlaceholderTmp =
      externalUrl.indexOf('[DATE') >= 0
          ? externalUrl.substring(externalUrl.indexOf('[DATE'))
          : null;
      let datePlaceholder = null;
      if (datePlaceholderTmp) {
        datePlaceholder = datePlaceholderTmp.substring(
          0,
          datePlaceholderTmp.indexOf(']') + 1
        );
      }
      if (datePlaceholder) {
        externalUrl = externalUrl
        .replace(
          `${datePlaceholder}`,
          moment(callDate).format(datePlaceholder.substring(datePlaceholder.indexOf(',') + 1, datePlaceholder.indexOf(']')).replace(' ', '%20'))
        );
      }
      externalUrl =
        externalUrl.indexOf('https://') < 0
          ? 'https://' + externalUrl
          : externalUrl;
    }
  }

  return interlocutor ? (
    <div
      className="media"
      style={{
        height:
          addContactButton && !interlocutor.fullname && !fromQueueName
            ? '1.5em'
            : '3.15em',
        paddingTop: align === 'right' ? '0.25em' : '0',
        alignItems:
          align === 'left' || !interlocutor.fullname
            ? 'flex-right'
            : 'flex-end',
      }}
    >
      {align === 'left' &&
        interlocutor.fullname &&
        (interlocutor.interlocutorType === 'U' ? (
          <UserAvatar
            src={interlocutor.avatar}
            alt={interlocutor.departmentFullname}
            size="md"
            className="mr-1"
            id={interlocutor.id}
            withPopover={avatarPopover}
          />
        ) : (
          <>
            <ContactAvatar
              src={interlocutor.avatar}
              alt={interlocutor.fullname}
              size="md"
              className="mr-1"
              id={interlocutor.id}
              withPopover={avatarPopover}
            />
          </>
        ))}
      <div
        style={{
          textAlign: align,
          width: '76%',
          paddingTop: conference ? '5px' : 0,
          paddingBottom: conference ? '18px' : 0,
        }}
        className="media-body"
      >
        {interlocutor.fullname && (
          <div style={ellipsis && style.ellipsis}>
            <b>
              {interlocutor.departmentFullname
                ? interlocutor.departmentFullname
                : interlocutor.fullname}
            </b>
          </div>
        )}
        {confToolbar && (
          <InterlocutorConferenceToolbar
            interlocutor={interlocutor}
            align={align}
            onHangup={onHangup}
            onJoin={onJoin}
          />
        )}
        {callToolbar && (
          <InterlocutorCallToolbar
            interlocutor={interlocutor}
            align={align}
            onPickup={
              (interlocutor.isExtension &&
                (interlocutor.isRinging || interlocutor.isHold) &&
                interlocutor.direction === PhoneEnums.Direction.In) ||
              interlocutor.isParked ||
              interlocutor.isQueued
                ? onPickup
                : null
            }
            onSpy={!interlocutor.isRinging && onSpy}
            onIntrude={!interlocutor.isRinging && onIntrude}
            fromQueueName={fromQueueName}
          />
        )}
        {!callToolbar && !confToolbar && (
          <div
            style={{
              lineHeight: 1,
            }}
          >
            {interlocutor.type && (
              <span className="pr-1">
                <b>
                  {messages[interlocutor.type]
                    ? formatMessage(messages[interlocutor.type])
                    : interlocutor.type}
                </b>
              </span>
            )}

            {number}

            {externalUrl ? (
              <a
                className="pr-2"
                href={externalUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  name="link-2"
                  width={20}
                  height={20}
                  color="var(--yn-blue-darker)"
                  style={{ position: 'relative', top: '5px', left: '3px' }}
                >
                  {formatMessage(messages.externalUrlButton)}
                </Icon>
              </a>
            ) : (
              <></>
            )}
            {!callToolbar &&
              interlocutor.interlocutorType === 'A' &&
              interlocutor.fullname && (
                <Link
                  className="mr-1"
                  to={`/abook/contacts/info/id/${interlocutor.id}`}
                >
                  <Icon
                    name="id-card-4"
                    width={20}
                    height={20}
                    color="var(--yn-blue-darker)"
                    style={{ position: 'relative', top: '5px', left: '3px' }}
                  >
                    {formatMessage(messages.infoButton)}
                  </Icon>
                </Link>
              )}
            {addContactButton &&
              !interlocutor.fullname &&
              interlocutor.number !== '<unknown>' &&
              contactGrant && (
                <>
                  <Link to={`/abook/contacts/info/number/${number}`}>
                    <Icon
                      name="id-card-4"
                      width={20}
                      height={20}
                      color="var(--yn-blue-darker)"
                      style={{ position: 'relative', top: '5px', left: '3px' }}
                    >
                      {formatMessage(messages.infoButton)}
                    </Icon>
                  </Link>
                  <AddContactButton number={number} />
                </>
              )}
          </div>
        )}
        {!confToolbar && fromQueueName && (
          <div
            style={{
              lineHeight: 0.9,
              color: 'var(--yn-blue)',
              fontSize: '12px',
            }}
          >
            <b>>> {fromQueueName}</b>
          </div>
        )}
      </div>
      {align === 'right' &&
        interlocutor.fullname &&
        (interlocutor.interlocutorType === 'U' ? (
          <UserAvatar
            src={interlocutor.avatar}
            alt={interlocutor.departmentFullname}
            size="md"
            className="ml-1"
            id={interlocutor.id}
            withPopover={avatarPopover}
          />
        ) : (
          <ContactAvatar
            src={interlocutor.avatar}
            alt={interlocutor.fullname}
            size="md"
            className="ml-1"
            id={interlocutor.id}
            withPopover={avatarPopover}
          />
        ))}
    </div>
  ) : (
    <div>...</div>
  );
};

InterlocutorPanel.propTypes = {
  interlocutor: PropTypes.object.isRequired,
  align: PropTypes.string,
  callToolbar: PropTypes.bool,
  confToolbar: PropTypes.bool,
  addContactButton: PropTypes.bool,
  conference: PropTypes.bool,
  onPickup: PropTypes.func,
  avatarPopover: PropTypes.bool,
  callingContactDetails: PropTypes.bool,
};

InterlocutorPanel.defaultProps = {
  align: 'left',
  callToolbar: false,
  confToolbar: false,
  addContactButton: false,
  conference: false,
  onPickup: undefined,
  avatarPopover: true,
  callingContactDetails: false,
};

function mapStateToProps(state) {
  return {
    contactGrant: addressbookGrant(state),
    externalUrlAlways: getPhoneExternalUrlsAlways(state),
  };
}

export default injectIntl(connect(mapStateToProps)(InterlocutorPanel));
