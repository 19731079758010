import { createSelector } from 'reselect';

const defaultList = [];
const defaultObject = [];

export const getPunchingsFilter = (state) => state.presences.filter;
export const getFetchUsersPunchingsLoaded = (state) =>
  state.presences.fetchPunchingsLoaded;
export const getFetchUsersPunchingsError = (state) =>
  state.presences.fetchPunchingsError;
export const getUsersPunchingsAllIds = (state) => state.presences.allIds;
export const getUsersPunchingById = (state, id) => state.presences.allById[id];
export const getTotalPunchings = (state) => state.presences.totalPunchings;
export const getCurrentPage = (state) => state.presences.currentPage;
export const getWorkingUsersFilter = (state) =>
  state.presences.workingUsersFilter;
export const getWorkingUsersLoaded = (state) =>
  state.presences.fetchWorkingUsersLoaded;
export const getWorkingUsersError = (state) =>
  state.presences.fetchWorkingUsersError;
export const getUsersWorkingUsersAllIds = (state) =>
  state.presences.workingUsersAllIds;
export const getWorkingUsersById = (state, id) =>
  state.presences.workingUsersAllById[id];
export const getTotalWorkingUsers = (state) =>
  state.presences.totalWorkingUsers;
export const getWorkingUsersCurrentPage = (state) =>
  state.presences.workingUsersCurrentPage;
export const getUsersWorkingUsersExcelLoaded = (state) =>
  state.presences.fetchWorkingUsersExcelLoaded;
export const getUsersWorkingUsersExcel = (state) =>
  state.presences.workingUsersExcel;
export const getUsersWorkingUsersExcelError = (state) =>
  state.presences.fetchWorkingUsersExcelError;
export const isPunchingPlanLoaded = (state) =>
  state.presences.punchingPlan.loaded;
export const getPunchingPlanPunchings = (state) =>
  state.presences.punchingPlan.punchings || defaultList;
export const getPunchingPlanVacations = (state) =>
  state.presences.punchingPlan.vacations || defaultList;
export const getPunchingPlanAbsence = (state) =>
  state.presences.punchingPlan.absences || defaultList;
export const isPunchingPlanError = (state) =>
  state.presences.punchingPlan.error;
export const isOtherPunchingSaveError = (state) =>
  state.presences.saveOtherPunchingError;
export const isOtherPunchingSaveLoaded = (state) =>
  state.presences.saveOtherPunchingLoaded;
export const getFetchTemplatesLoaded = (state) =>
  state.exportTemplates.fetchTemplatesLoaded;
export const getFetchTemplatesError = (state) =>
  state.exportTemplates.fetchTemplatesError;
export const getFetchTemplateLoaded = (state) =>
  state.exportTemplates.fetchTemplateLoaded;
export const getFetchTemplateError = (state) =>
  state.exportTemplates.fetchTemplateError;
export const getSaveTemplateLoaded = (state) =>
  state.exportTemplates.saveTemplateLoaded;
export const getSaveTemplateError = (state) =>
  state.exportTemplates.saveTemplateError;
export const getTemplatesAllIds = (state) => state.exportTemplates.allIds;
export const getTemplateById = (state, id) =>
  state.exportTemplates.byId[id] || defaultObject;
export const getTemplatePunchingById = (state, id) =>
  state.exportTemplates.byId[id];
export const isExportPunchingsLoaded = (state) =>
  state.exportTemplates.exportPunchingsLoaded;
export const isExportPunchingsError = (state) =>
  state.exportTemplates.exportPunchingsError;
export const getDeleteTemplateError = (state) =>
  state.exportTemplates.deleteTemplateError;
export const getAbsenceFilter = (state) => state.presences.absence.filter;
export const getFetchFirstAbsences = (state) =>
  state.presences.fetchFirstAbsences;
export const getFetchAbsenceLoaded = (state) =>
  state.presences.fetchAbsenceLoaded;
export const getFetchAbsenceError = (state) =>
  state.presences.fetchAbsenceError;
export const getAbsencesAllIds = (state) => state.presences.absence.allIds;
export const getAbsenceById = (state, id) =>
  state.presences.absence.allById[id];
export const getTotalAbsences = (state) => state.presences.absence.total;
export const getCurrentAbsencePage = (state) =>
  state.presences.absence.currentPage;
export const getSaveAbsenceLoaded = (state) =>
  state.presences.saveAbsenceLoaded;
export const getSaveAbsenceError = (state) => state.presences.saveAbsenceError;
export const getDeleteAbsenceError = (state) =>
  state.presences.deleteAbsenceError;
export const getFetchQRCodeSettingsLoaded = (state) =>
  state.presences.fetchQRCodeSettingsLoaded;
export const getFetchQRCodeSettingsError = (state) =>
  state.presences.fetchQRCodeSettingsError;
export const getSaveQRCodeSettingsLoaded = (state) =>
  state.presences.saveQRCodeSettingsLoaded;
export const getSaveQRCodeSettingsError = (state) =>
  state.presences.saveQRCodeSettingsError;
export const getQRCodeSettings = (state) => state.presences.QRCodeSettings;
export const getFetchGuestDataLoaded = (state) =>
  state.presences.fetchGuestDataLoaded;
export const getFetchGuestDataError = (state) =>
  state.presences.fetchGuestDataError;
export const getGuestData = (state) => state.presences.guestData;
export const getSaveGuestVisitLoaded = (state) =>
  state.presences.saveGuestVisitLoaded;
export const getSaveGuestVisitError = (state) =>
  state.presences.saveGuestVisitError;
export const getSaveGuestVisitSuccess = (state) =>
  state.presences.saveGuestVisitSuccess;
export const getFetchGuestVisitsLoaded = (state) =>
  state.presences.fetchGuestVisitsLoaded;
export const getFetchGuestVisitsError = (state) =>
  state.presences.fetchGuestVisitsError;
export const getGuestVisitsTotal = (state) => state.presences.guestVisits.total;
export const getCurrentGuestVisitsPage = (state) =>
  state.presences.guestVisits.currentPage;
export const getGuestVisitsAllIds = (state) =>
  state.presences.guestVisits.pagedIds;
const getGuestVisitsAllIdsExcel = (state) => state.presences.guestVisits.allIds;
export const getGuestVisitsById = (state, id) =>
  state.presences.guestVisits.byId[id];
const getAllGuestVisitsById = (state) => state.presences.guestVisits.byId;
export const getGuestVisitsByAllIds = createSelector(
  [getAllGuestVisitsById, getGuestVisitsAllIdsExcel],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getJustifications = (state) => state.presences.justifications;
export const getInJustifications = (state) =>
  state.presences.justifications.filter(
    (justification) =>
      justification.direction === 'in' || justification.direction === 'both'
  );
export const getOutJustifications = (state) =>
  state.presences.justifications.filter(
    (justification) =>
      justification.direction === 'out' || justification.direction === 'both'
  );
export const getJustificationFromId = (state, id) =>
  state.presences.justifications.find(
    (justification) => justification.id === id
  );
export const isJustificationsLoaded = (state) =>
  state.presences.fetchJustificationsLoaded;
export const getJustificationsError = (state) =>
  state.presences.fetchJustificationsError;
export const isInsertJustificationLoaded = (state) =>
  state.presences.insertJustificationLoaded;
export const getInsertJustificationError = (state) =>
  state.presences.insertJustificationError;
export const isInsertJustificationSuccess = (state) =>
  state.presences.insertJustificationSuccess;
export const isDeleteJustificationLoaded = (state) =>
  state.presences.deleteJustificationLoaded;
export const getErrorDeleteJustification = (state) =>
  state.presences.deleteJustificationError;
export const isDeleteJustificationSuccess = (state) =>
  state.presences.deleteJustificationSuccess;
