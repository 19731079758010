import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { v1 as uuidv1 } from 'uuid';
import PropTypes from 'prop-types';
import OtherPhoneButton from './buttons/OtherPhoneButton';
import Label from './formControls/Label';
import FaxButton from './buttons/FaxButton';
import { Link } from 'react-router-dom';
import Icon from './icons/Icon';
import { getPhoneExternalUrlsAlways } from '../js/externalUrl/selectors';
import { ExternalUrlUtils } from '../js/externalUrl/ExternalUrlUtils';

const messages = defineMessages({
  MOBILE: {
    id: 'PhoneLabel.label.MOBILE',
    defaultMessage: 'Mobile: ',
  },
  OFFICE: {
    id: 'PhoneLabel.label.OFFICE',
    defaultMessage: 'Office: ',
  },
  HOME: {
    id: 'PhoneLabel.label.HOME',
    defaultMessage: 'Home: ',
  },
  FAX: {
    id: 'PhoneLabel.label.FAX',
    defaultMessage: 'Fax: ',
  },
  OTHERS: {
    id: 'PhoneLabel.label.OTHERS',
    defaultMessage: 'Others: ',
  },
  sendSMS: {
    id: 'PhoneLabel.tooltip.sendSMS',
    defaultMessage: 'Send an SMS',
  },
  externalUrlButton: {
    id: 'PhoneLabel.tooltip.externalUrlButton',
    defaultMessage: 'Open external url',
  },
});

const PhoneLabel = ({ number, showType, showSMS, contactName, clientNumber, externalUrlAlways, intl: { formatMessage } }) => {
  let externalUrl;
  if (externalUrlAlways && externalUrlAlways.length) {
    if (
      externalUrlAlways[0].constraint !== ExternalUrlUtils.Constraint.EXTENSION
    ) {
      externalUrl = externalUrlAlways[0].link
        .replace('[CALLER]', number.number)
        .replace('[CALLERNAME]', contactName || '')
        .replace('[CLIENTNUMBER]', clientNumber);
      externalUrl =
        externalUrl.indexOf('https://') < 0
          ? 'https://' + externalUrl
          : externalUrl;
    }
  }
  return (
    <div id={`phone-number-${number.number}`} className="pb-1">
      {showType && (
        <Label for={`phone-number-${number.number}`}>
          {messages[number.type]
            ? formatMessage(messages[number.type])
            : number.type}
        </Label>
      )}
      <span style={{ position: 'relative' }}>
        <span style={{ paddingRight: '1.5em' }}>{number.number}</span>
        {number.type === 'FAX' ? (
          <span style={{ position: 'absolute', top: '-3px', right: '-5px' }}>
            <FaxButton number={number.number} />
          </span>
        ) : (
          <>
            <span style={{ position: 'absolute', top: '-3px', right: '-5px' }}>
              <OtherPhoneButton
                elementId={`phone_popover_${uuidv1()}`}
                numbers={[{ number: number.number }]}
              />
            </span>
            {showSMS && (
              <Link
                style={{ position: 'absolute', top: '-2px', right: '-30px' }}
                to={`/sms/sent/edit?phone=${number.number}`}
              >
                <Icon
                  name="bubble-chat-1-filled"
                  width={20}
                  height={20}
                  color="var(--yn-blue-dark)"
                  clickable
                >
                  {formatMessage(messages.sendSMS)}
                </Icon>
              </Link>
            )}
            {externalUrl ? (
              <a
                style={{
                  position: 'absolute',
                  top: '-8px',
                  right: `${showSMS ? '-55px' : '-30px'}`,
                }}
                href={externalUrl}
                className="ml-1"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  name="link-2"
                  width={20}
                  height={20}
                  color="var(--yn-blue-darker)"
                  style={{
                    position: 'relative',
                    top: '5px',
                    left: '3px',
                  }}
                >
                  {formatMessage(messages.externalUrlButton)}
                </Icon>
              </a>
            ) : (
              <></>
            )}
          </>
        )}
      </span>
    </div>
  );
};

PhoneLabel.propTypes = {
  number: PropTypes.shape({
    type: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
  showType: PropTypes.bool,
  showSMS: PropTypes.bool,
};
PhoneLabel.defaultProps = {
  showType: false,
  showSMS: false,
};

function mapStateToProps(state) {
  return {
    externalUrlAlways: getPhoneExternalUrlsAlways(state),
  };
}

export default injectIntl(connect(mapStateToProps)(PhoneLabel));
