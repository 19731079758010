export const SET_PUNCHINGS_FILTER = 'SET_PUNCHINGS_FILTER';
export const SET_MANAGE_PUNCHINGS_FILTER = 'SET_MANAGE_PUNCHINGS_FILTER';
export const FETCH_PUNCHINGS_REQUEST = 'FETCH_PUNCHINGS_REQUEST';
export const FETCH_PUNCHINGS_SUCCESS = 'FETCH_PUNCHINGS_SUCCESS';
export const FETCH_PUNCHINGS_FAILURE = 'FETCH_PUNCHINGS_FAILURE';
export const SAVE_PUNCHING_REQUEST = 'SAVE_PUNCHING_REQUEST';
export const SAVE_PUNCHING_FAILURE = 'SAVE_PUNCHING_FAILURE';
export const SAVE_PUNCHING_SUCCESS = 'SAVE_PUNCHING_SUCCESS';
export const FETCH_PRESENCE_SETTINGS_REQUEST =
  'FETCH_PRESENCE_SETTINGS_REQUEST';
export const FETCH_PRESENCE_SETTINGS_SUCCESS =
  'FETCH_PRESENCE_SETTINGS_SUCCESS';
export const FETCH_PRESENCE_SETTINGS_FAILURE =
  'FETCH_PRESENCE_SETTINGS_FAILURE';
export const FETCH_VACATIONS_TO_VALIDATE_REQUEST =
  'FETCH_VACATIONS_TO_VALIDATE_REQUEST';
export const FETCH_VACATIONS_TO_VALIDATE_SUCCESS =
  'FETCH_VACATIONS_TO_VALIDATE_SUCCESS';
export const FETCH_VACATIONS_TO_VALIDATE_FAILURE =
  'FETCH_VACATIONS_TO_VALIDATE_FAILURE';
export const SET_VACATIONS_FILTER = 'SET_VACATIONS_FILTER';
export const FETCH_PUNCHING_DETAILS_REQUEST = 'FETCH_PUNCHING_DETAILS_REQUEST';
export const FETCH_PUNCHING_DETAILS_SUCCESS = 'FETCH_PUNCHING_DETAILS_SUCCESS';
export const FETCH_PUNCHING_DETAILS_FAILURE = 'FETCH_PUNCHING_DETAILS_FAILURE';
export const FETCH_USERS_PUNCHINGS_REQUEST = 'FETCH_USERS_PUNCHINGS_REQUEST';
export const FETCH_USERS_PUNCHINGS_SUCCESS = 'FETCH_USERS_PUNCHINGS_SUCCESS';
export const FETCH_USERS_PUNCHINGS_FAILURE = 'FETCH_USERS_PUNCHINGS_FAILURE';
export const FETCH_PUNCHINGS_PLAN_REQUEST = 'FETCH_PUNCHINGS_PLAN_REQUEST';
export const FETCH_PUNCHINGS_PLAN_SUCCESS = 'FETCH_PUNCHINGS_PLAN_SUCCESS';
export const FETCH_PUNCHINGS_PLAN_FAILURE = 'FETCH_PUNCHINGS_PLAN_FAILURE';
export const SAVE_OTHER_PUNCHING_REQUEST = 'SAVE_OTHER_PUNCHING_REQUEST';
export const SAVE_OTHER_PUNCHING_FAILURE = 'SAVE_OTHER_PUNCHING_FAILURE';
export const SAVE_OTHER_PUNCHING_SUCCESS = 'SAVE_OTHER_PUNCHING_SUCCESS';

export const INSERT_VACATION_NOTIFICATION = 'INSERT_VACATION_NOTIFICATION';
export const DELETE_VACATION_NOTIFICATION = 'DELETE_VACATION_NOTIFICATION';
export const ASK_DELETING_VACATION_NOTIFICATION =
  'ASK_DELETING_VACATION_NOTIFICATION';
export const CONFIRM_VACATION_NOTIFICATION = 'CONFIRM_VACATION_NOTIFICATION';
export const REJECT_VACATION_NOTIFICATION = 'REJECT_VACATION_NOTIFICATION';
export const CONFIRM_DELETING_VACATION_NOTIFICATION =
  'CONFIRM_DELETING_VACATION_NOTIFICATION';
export const REJECT_DELETING_VACATION_NOTIFICATION =
  'REJECT_DELETING_VACATION_NOTIFICATION';
export const INSERT_PUNCHING_NOTIFICATION = 'INSERT_PUNCHING_NOTIFICATION';
export const CONFIRM_PUNCHING_NOTIFICATION = 'CONFIRM_PUNCHING_NOTIFICATION';
export const REJECT_PUNCHING_NOTIFICATION = 'REJECT_PUNCHING_NOTIFICATION';
export const FETCH_WORKING_USERS_REQUEST = 'FETCH_WORKING_USERS_REQUEST';
export const FETCH_WORKING_USERS_SUCCESS = 'FETCH_WORKING_USERS_SUCCESS';
export const FETCH_WORKING_USERS_FAILURE = 'FETCH_WORKING_USERS_FAILURE';
export const FETCH_WORKING_USERS_EXCEL_SUCCESS =
  'FETCH_WORKING_USERS_EXCEL_SUCCESS';
export const FETCH_WORKING_USERS_EXCEL_FAILURE =
  'FETCH_WORKING_USERS_EXCEL_FAILURE';
export const SET_WORKING_USERS_FILTER = 'SET_WORKING_USERS_FILTER';
export const FETCH_PBX_REGISTRY_REQUEST = 'FETCH_PBX_REGISTRY_REQUEST';
export const FETCH_PBX_REGISTRY_SUCCESS = 'FETCH_PBX_REGISTRY_SUCCESS';
export const FETCH_PBX_REGISTRY_FAILURE = 'FETCH_PBX_REGISTRY_FAILURE';
export const SAVE_PBX_REGISTRY_REQUEST = 'SAVE_PBX_REGISTRY_REQUEST';
export const SAVE_PBX_REGISTRY_SUCCESS = 'SAVE_PBX_REGISTRY_SUCCESS';
export const SAVE_PBX_REGISTRY_FAILURE = 'SAVE_PBX_REGISTRY_FAILURE';
export const FETCH_EXPORT_TEMPLATES_REQUEST = 'FETCH_EXPORT_TEMPLATES_REQUEST';
export const FETCH_EXPORT_TEMPLATES_SUCCESS = 'FETCH_EXPORT_TEMPLATES_SUCCESS';
export const FETCH_EXPORT_TEMPLATES_FAILURE = 'FETCH_EXPORT_TEMPLATES_FAILURE';
export const FETCH_EXPORT_TEMPLATE_REQUEST = 'FETCH_EXPORT_TEMPLATE_REQUEST';
export const FETCH_EXPORT_TEMPLATE_SUCCESS = 'FETCH_EXPORT_TEMPLATE_SUCCESS';
export const FETCH_EXPORT_TEMPLATE_FAILURE = 'FETCH_EXPORT_TEMPLATE_FAILURE';
export const SAVE_EXPORT_TEMPLATE_REQUEST = 'SAVE_EXPORT_TEMPLATE_REQUEST';
export const SAVE_EXPORT_TEMPLATE_SUCCESS = 'SAVE_EXPORT_TEMPLATE_SUCCESS';
export const SAVE_EXPORT_TEMPLATE_FAILURE = 'SAVE_EXPORT_TEMPLATE_FAILURE';
export const DELETE_EXPORT_TEMPLATE_REQUEST = 'DELETE_EXPORT_TEMPLATE_REQUEST';
export const DELETE_EXPORT_TEMPLATE_SUCCESS = 'DELETE_EXPORT_TEMPLATE_SUCCESS';
export const DELETE_EXPORT_TEMPLATE_FAILURE = 'DELETE_EXPORT_TEMPLATE_FAILURE';
export const SET_PUNCHINGS_TO_VALIDATE_FILTER =
  'SET_PUNCHINGS_TO_VALIDATE_FILTER';
export const FETCH_PUNCHINGS_TO_VALIDATE_REQUEST =
  'FETCH_PUNCHINGS_TO_VALIDATE_REQUEST';
export const FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS =
  'FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS';
export const FETCH_PUNCHINGS_TO_VALIDATE_FAILURE =
  'FETCH_PUNCHINGS_TO_VALIDATE_FAILURE';
export const VALIDATE_PUNCHING_REQUEST = 'VALIDATE_PUNCHING_REQUEST';
export const VALIDATE_PUNCHING_FAILURE = 'VALIDATE_PUNCHING_FAILURE';
export const VALIDATE_PUNCHING_SUCCESS = 'VALIDATE_PUNCHING_SUCCESS';
export const FETCH_ABSENCE_REQUEST = 'FETCH_ABSENCE_REQUEST';
export const FETCH_ABSENCE_SUCCESS = 'FETCH_ABSENCE_SUCCESS';
export const FETCH_ABSENCE_FAILURE = 'FETCH_ABSENCE_FAILURE';
export const SET_ABSENCE_FILTER = 'SET_ABSENCE_FILTER';
export const SAVE_ABSENCE_REQUEST = 'SAVE_ABSENCE_REQUEST';
export const SAVE_ABSENCE_SUCCESS = 'SAVE_ABSENCE_SUCCESS';
export const SAVE_ABSENCE_FAILURE = 'SAVE_ABSENCE_FAILURE';
export const DELETE_ABSENCE_REQUEST = 'DELETE_ABSENCE_REQUEST';
export const DELETE_ABSENCE_SUCCESS = 'DELETE_ABSENCE_SUCCESS';
export const DELETE_ABSENCE_FAILURE = 'DELETE_ABSENCE_FAILURE';
export const FETCH_QRCODE_SETTINGS_REQUEST = 'FETCH_QRCODE_SETTINGS_REQUEST';
export const FETCH_QRCODE_SETTINGS_SUCCESS = 'FETCH_QRCODE_SETTINGS_SUCCESS';
export const FETCH_QRCODE_SETTINGS_FAILURE = 'FETCH_QRCODE_SETTINGS_FAILURE';
export const SAVE_QRCODE_SETTINGS_REQUEST = 'SAVE_QRCODEE_SETTINGS_REQUEST';
export const SAVE_QRCODE_SETTINGS_SUCCESS = 'SAVE_QRCODE_SETTINGS_SUCCESS';
export const SAVE_QRCODE_SETTINGS_FAILURE = 'SAVE_QRCODE_SETTINGS_FAILURE';
export const FETCH_GUEST_DATA_REQUEST = 'FETCH_GUEST_DATA_REQUEST';
export const FETCH_GUEST_DATA_SUCCESS = 'FETCH_GUEST_DATA_SUCCESS';
export const FETCH_GUEST_DATA_FAILURE = 'FETCH_GUEST_DATA_FAILURE';
export const SAVE_GUEST_VISIT_REQUEST = 'SAVE_GUEST_VISIT_REQUEST';
export const SAVE_GUEST_VISIT_SUCCESS = 'SAVE_GUEST_VISIT_SUCCESS';
export const SAVE_GUEST_VISIT_FAILURE = 'SAVE_GUEST_VISIT_FAILURE';
export const FETCH_GUEST_VISITS_REQUEST = 'FETCH_GUEST_VISITS_REQUEST';
export const FETCH_GUEST_VISITS_SUCCESS = 'FETCH_GUEST_VISITS_SUCCESS';
export const FETCH_GUEST_VISITS_FAILURE = 'FETCH_GUEST_VISITS_FAILURE';
export const FETCH_PUNCHING_JUSTIFICATIONS_REQUEST =
  'FETCH_PUNCHING_JUSTIFICATIONS_REQUEST';
export const FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS =
  'FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS';
export const FETCH_PUNCHING_JUSTIFICATIONS_FAILURE =
  'FETCH_PUNCHING_JUSTIFICATIONS_FAILURE';
export const DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST =
  'DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST';
export const DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS =
  'DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS';
export const DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE =
  'DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE';
export const EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST =
  'EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST';
export const EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS =
  'EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS';
export const EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE =
  'EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE';
export const DELETE_OTHER_PUNCHING_REQUEST = 'DELETE_OTHER_PUNCHING_REQUEST';
export const DELETE_OTHER_PUNCHING_SUCCESS = 'DELETE_OTHER_PUNCHING_SUCCESS';
export const DELETE_OTHER_PUNCHING_FAILURE = 'DELETE_OTHER_PUNCHING_FAILURE';
export const EDIT_OTHER_PUNCHING_REQUEST = 'EDIT_OTHER_PUNCHING_REQUEST';
export const EDIT_OTHER_PUNCHING_SUCCESS = 'EDIT_OTHER_PUNCHING_SUCCESS';
export const EDIT_OTHER_PUNCHING_FAILURE = 'EDIT_OTHER_PUNCHING_FAILURE';
