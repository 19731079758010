import { request } from '../request';

export class NethesisCommands {
  constructor(data) {
    this.url = data.url;
    this.username = data.username;
    this.authenticationToken = null;
  }

  setAuthenticationToken = (authenticationToken) => {
    this.authenticationToken = authenticationToken;
  };

  login = (password) =>
    request(
      {
        url: '/webrest/authentication/login',
        method: 'POST',
        data: {
          username: this.username,
          password,
        },
      },
      {
        baseUrl: `https://${this.url}`,
      }
    );

  logout = () =>
    request(
      {
        url: '/webrest/authentication/logout',
        method: 'POST',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  getExtensionsStatus = () =>
    request(
      {
        url: '/webrest/astproxy/extensions',
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  getParkingsStatus = () =>
    request(
      {
        url: '/webrest/astproxy/parkings',
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  getQueuesStatus = () =>
    request(
      {
        url: '/webrest/astproxy/queues',
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  call = (number, endpointId, endpointType) =>
    request(
      {
        url: '/webrest/astproxy/call',
        method: 'POST',
        data: {
          number,
          endpointId,
          endpointType,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  toggleDND = (status) =>
    request(
      {
        url: '/webrest/user/presence',
        method: 'POST',
        data: {
          status,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  hangupCall = (convid, endpointId, endpointType) =>
    request(
      {
        url: '/webrest/astproxy/hangup',
        method: 'POST',
        data: {
          endpointId,
          convid,
          endpointType,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  attendedTransfer = (convid, endpointId, to) =>
    request(
      {
        url: '/webrest/astproxy/atxfer',
        method: 'POST',
        data: {
          to,
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  blindTransfer = (convid, endpointId, to) =>
    request(
      {
        url: '/webrest/astproxy/blindtransfer',
        method: 'POST',
        data: {
          to,
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  blindTransferParking = (parking, to) =>
    request(
      {
        url: '/webrest/astproxy/blindtransfer_parking',
        method: 'POST',
        data: {
          to,
          parking,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  pickupQueue = (queue, waitCallerId, destId) =>
    request(
      {
        url: '/webrest/astproxy/pickup_qwaitcaller',
        method: 'POST',
        data: {
          queue,
          waitCallerId,
          destId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  pickupParking = (parking, destId) =>
    request(
      {
        url: '/webrest/astproxy/pickup_parking',
        method: 'POST',
        data: {
          destId,
          parking,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  park = (convid, endpointId, applicantId) =>
    request(
      {
        url: '/webrest/astproxy/park',
        method: 'POST',
        data: {
          convid,
          applicantId,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  pickup = (convid, endpointId, destId) =>
    request(
      {
        url: '/webrest/astproxy/pickup_conv',
        method: 'POST',
        data: {
          destId,
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  mute = (convid, endpointId) =>
    request(
      {
        url: '/webrest/astproxy/mute',
        method: 'POST',
        data: {
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  unmute = (convid, endpointId) =>
    request(
      {
        url: '/webrest/astproxy/unmute',
        method: 'POST',
        data: {
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  startConference = (convid, addEndpointId, ownerEndpointId) =>
    request(
      {
        url: '/webrest/astproxy/start_conf',
        method: 'POST',
        data: {
          convid,
          addEndpointId,
          ownerEndpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  endConference = (confId) =>
    request(
      {
        url: '/webrest/astproxy/end_conf',
        method: 'POST',
        data: {
          confId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  joinConference = (endpointId) =>
    request(
      {
        url: '/webrest/astproxy/join_myconf',
        method: 'POST',
        data: {
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  hangupUserInConference = (extenId, confId) =>
    request(
      {
        url: '/webrest/astproxy/hangup_userconf',
        method: 'POST',
        data: {
          extenId,
          confId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  muteUserInConference = (userId, confId) =>
    request(
      {
        url: '/webrest/astproxy/mute_userconf',
        method: 'POST',
        data: {
          userId,
          confId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  retrieveConferenceData = (owner) =>
    new Promise((resolve, reject) => {
      setTimeout(
        () =>
          request(
            {
              url: `/webrest/astproxy/conference/${owner}`,
              method: 'GET',
            },
            {
              baseUrl: `https://${this.url}`,
              headers: {
                Authorization: `${this.username}:${this.authenticationToken}`,
              },
            }
          )
            .then((response) => resolve(response))
            .catch((err) => reject(err)),
        500
      );
    });

  unmuteUserInConference = (userId, confId) =>
    request(
      {
        url: '/webrest/astproxy/unmute_userconf',
        method: 'POST',
        data: {
          userId,
          confId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  spyCall = (convid, endpointId, destId) =>
    request(
      {
        url: '/webrest/astproxy/start_spy',
        method: 'POST',
        data: {
          convid,
          endpointId,
          destId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  intrudeCall = (convid, endpointId, destId) =>
    request(
      {
        url: '/webrest/astproxy/intrude',
        method: 'POST',
        data: {
          convid,
          endpointId,
          destId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  startRecording = (convid, endpointId) =>
    request(
      {
        url: '/webrest/astproxy/start_record',
        method: 'POST',
        data: {
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  stopRecording = (convid, endpointId) =>
    request(
      {
        url: '/webrest/astproxy/stop_record',
        method: 'POST',
        data: {
          convid,
          endpointId,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  getCdr = (username, start, end, filters, extraFilters) => {
    const filtersPath = filters ? `/${filters}` : '';
    const extraPath = extraFilters.length ? `?${extraFilters.join('&')}` : '';
    return request(
      {
        url: `/webrest/historycall/interval/user/${username}/${start}/${end}${filtersPath}${extraPath}`,
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );
  };

  getCdrSwitchboard = (start, end, filters, onlyIncoming) => {
    const filtersPath = filters ? `/${filters}` : '';
    return request(
      {
        url: `/webrest/histcallswitch/interval/${start}/${end}${filtersPath}${
          onlyIncoming ? '?type=in' : ''
        }`,
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );
  };

  saveWakeup = (wakeupData) =>
    request(
      {
        url: '/webrest/astproxy/wakeup',
        method: 'POST',
        data: wakeupData,
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  loadWakeups = () =>
    new Promise((resolve, reject) => {
      request(
        {
          url: '/webrest/astproxy/wakeup',
          method: 'GET',
        },
        {
          baseUrl: `https://${this.url}`,
          headers: {
            Authorization: `${this.username}:${this.authenticationToken}`,
          },
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });

  removeWakeup = (id) =>
    request(
      {
        url: '/webrest/astproxy/wakeup',
        method: 'DELETE',
        data: {
          filename: id,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  enableDevice = (id) =>
    request(
      {
        url: '/webrest/user/default_device',
        method: 'POST',
        data: {
          id,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  retrieveDefaultDevice = () =>
    new Promise((resolve, reject) => {
      request(
        {
          url: '/webrest/user/me',
          method: 'GET',
        },
        {
          baseUrl: `https://${this.url}`,
          headers: {
            Authorization: `${this.username}:${this.authenticationToken}`,
          },
        }
      )
        .then((res) => {
          resolve(res.data.default_device.type);
        })
        .catch((err) => reject(err));
    });

  loadOwnVboxGreeting = () =>
    request(
      {
        url: '/webrest/voicemail/listen_custom_msg/unavail',
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  hangupChannel = (channel, endpointId, endpointType) =>
    request(
      {
        url: '/webrest/astproxy/hangup_channel',
        method: 'POST',
        data: {
          endpointId,
          channel,
          endpointType,
        },
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );

  getRoutes = () =>
    request(
      {
        url: `/webrest/offhour/list`,
        method: 'GET',
      },
      {
        baseUrl: `https://${this.url}`,
        headers: {
          Authorization: `${this.username}:${this.authenticationToken}`,
        },
      }
    );
}
