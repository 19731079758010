import { takeLatest, call, put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import filter from 'lodash.filter';
import {
  fetchHotelPricesSuccess,
  fetchHotelPricesFailure,
  saveHotelPriceSuccess,
  saveHotelPriceFailure,
  deleteHotelPriceSuccess,
  deleteHotelPriceFailure,
  resetHotelPricesSuccess,
  resetHotelPricesFailure,
  fetchHotelAreaPrefixesSuccess,
  fetchHotelAreaPrefixesFailure,
  fetchHotelCallsSuccess,
  fetchHotelCallsFailure,
  toggleHotelWakeupServiceSuccess,
  toggleHotelWakeupServiceFailure,
  fetchHotelWakeupSettingsSuccess,
  fetchHotelWakeupSettingsFailure,
  updateHotelWakeupSettingsSuccess,
  updateHotelWakeupSettingsFailure,
  fetchHotelActiveWakeupsSuccess,
  fetchHotelActiveWakeupsFailure,
  deleteHotelWakeupSuccess,
  deleteHotelWakeupFailure,
  saveHotelWakeupSuccess,
  saveHotelWakeupFailure,
  fetchHotelArchiveWakeupsSuccess,
  fetchHotelArchiveWakeupsFailure,
  fetchHotelActiveWakeupsRequest,
} from './actions';
import {
  FETCH_HOTEL_PRICES_REQUEST,
  SAVE_HOTEL_PRICES_REQUEST,
  DELETE_HOTEL_PRICE_REQUEST,
  RESET_HOTEL_PRICES_REQUEST,
  RESET_HOTEL_PRICES_SUCCESS,
  FETCH_HOTEL_AREA_PREFIXES_REQUEST,
  FETCH_HOTEL_CALLS_REQUEST,
  TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST,
  FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST,
  UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST,
  FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST,
  DELETE_HOTEL_WAKEUP_REQUEST,
  SAVE_HOTEL_WAKEUP_REQUEST,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST,
} from './types';
import api from '../api';
import { getPlatformLanguage } from '../settings/selectors';
import {
  getUserFromMainExtensionUsername,
  getUsersWithPhone,
} from '../users/selectors';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { isPhoneLogged } from '../phone/selectors';
import { PbxManager } from '../phone/PbxManager';
import { PhoneEnums } from '../phone/PhoneUtils';
import { getHotelActiveWakeupsFilter } from './selectors';
import { getCustomerId } from '../me/selectors';

function* fetchHotelPrices() {
  try {
    const res = yield call(api.hotel.getHotelPrices);
    yield call(checkApiResponse, res);
    yield put(fetchHotelPricesSuccess(res.data || []));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchHotelPricesFailure(error));
  }
}

function* fetchHotelAreaPrefixes() {
  try {
    const language = yield select(getPlatformLanguage);
    const res = yield call(api.hotel.getHotelAreaPrefixes, { language });
    yield call(checkApiResponse, res);
    yield put(fetchHotelAreaPrefixesSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchHotelAreaPrefixesFailure(error));
  }
}

function* saveHotelPrice(action) {
  try {
    let res;
    if (action.price.id) {
      res = yield call(api.hotel.updateHotelPrice, action.price);
    } else {
      res = yield call(api.hotel.createHotelPrice, action.price);
    }
    yield call(checkApiResponse, res);
    yield put(
      saveHotelPriceSuccess({
        ...action.price,
        id: res.data ? res.data.id || action.price.id : action.price.id,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveHotelPriceFailure(error));
  }
}

function* deleteHotelPrice(action) {
  try {
    const res = yield call(api.hotel.deleteHotelPrice, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteHotelPriceSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteHotelPriceFailure(error));
  }
}

function* resetHotelPrices() {
  try {
    const res = yield call(api.hotel.resetHotelPrices);
    yield call(checkApiResponse, res);
    yield put(resetHotelPricesSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(resetHotelPricesFailure(error));
  }
}

export function* fetchHotelCalls(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const searchingUsers = [];
    if (action.params.extensions) {
      for (let i = 0; i < action.params.extensions.length; i += 1) {
        const foundUsers = yield select(
          getUserFromMainExtensionUsername,
          action.params.extensions[i]
        );
        if (foundUsers && foundUsers.length > 0) {
          const searchingUser = {
            username: action.params.extensions[i],
            number: foundUsers[0].mainExtensionNumber,
          };
          searchingUsers.push(searchingUser);
        }
      }
    }
    const customerId = yield select(getCustomerId);
    const res = yield call(
      PbxManager.retrieveHotelCdr.bind(PbxManager, {
        ...action.params,
        searchingUsers,
      }, {
        customerId
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(fetchHotelCallsSuccess(res.data));
  } catch (err) {
    yield put(fetchHotelCallsFailure(err));
  }
}

function* toggleHotelWakeupService(action) {
  try {
    const res = yield call(api.hotel.toggleHotelWakeupEnable, {
      enabled: action.enabled,
      rebuild: true,
    });
    yield call(checkApiResponse, res);
    yield put(toggleHotelWakeupServiceSuccess(action.enabled));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(toggleHotelWakeupServiceFailure(error));
  }
}

function* fetchHotelWakeupSettings() {
  try {
    const res = yield call(api.hotel.getHotelWakeupSettings);
    yield call(checkApiResponse, res);
    yield put(fetchHotelWakeupSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchHotelWakeupSettingsFailure(error));
  }
}

function* updateHotelWakeupSettings(action) {
  try {
    const res = yield call(
      api.hotel.updateHotelWakeupSettings,
      action.settings
    );
    yield call(checkApiResponse, res);
    yield put(updateHotelWakeupSettingsSuccess(action.settings));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateHotelWakeupSettingsFailure(error));
  }
}

function* fetchHotelActiveWakeups(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const searchingUsers =
      action.params.users.length > 0
        ? filter(yield select(getUsersWithPhone), (o) => {
            return action.params.users.indexOf(o.id) > -1;
          }).map((user) => ({
            id: user.id,
            username: user.mainExtensionUsername,
            number: user.mainExtensionNumber,
          }))
        : [];
    const res = yield call(
      PbxManager.retrieveWakeups.bind(PbxManager, {
        ...action.params,
        searchingUsers,
        active: true,
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(fetchHotelActiveWakeupsSuccess(res.data));
  } catch (err) {
    yield put(fetchHotelActiveWakeupsFailure(err));
  }
}

function* fetchArchiveHotelWakeups(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const extensions = yield select(getUsersWithPhone);
    const res = yield call(
      PbxManager.retrieveWakeups.bind(PbxManager, {
        ...action.params,
        archive: true,
        searchingUsers: extensions.map((o) => ({
          id: o.id,
          number: o.mainExtensionNumber,
          username: o.mainExtensionUsername,
        })),
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(fetchHotelArchiveWakeupsSuccess(res.data));
  } catch (err) {
    yield put(fetchHotelArchiveWakeupsFailure(err));
  }
}

export function* saveHotelWakeup(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const res = yield call(
      PbxManager.saveWakeup.bind(PbxManager, action.wakeup)
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(saveHotelWakeupSuccess(action.wakeup));
    yield put(push('/hotel/wakeup/scheduling'));
  } catch (err) {
    yield put(saveHotelWakeupFailure(err.status));
  }
}

function* deleteHotelWakeup(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const notDeletedWakeupIds = [];
    if (action.ids && action.ids.length) {
      for (let i = 0; i < action.ids.length; i += 1) {
        try {
          const res = yield call(
            PbxManager.deleteWakeup.bind(PbxManager, action.ids[i])
          );
          if (res.status === PhoneEnums.CallsActionsResults.ko) {
            throw res;
          }
        } catch (err) {
          notDeletedWakeupIds.push(action.ids[i]);
        }
      }
    }
    if (!notDeletedWakeupIds.length) {
      yield put(deleteHotelWakeupSuccess());
      const filterWakeups = yield select(getHotelActiveWakeupsFilter);
      yield put(fetchHotelActiveWakeupsRequest(filterWakeups));
    } else {
      yield put(deleteHotelWakeupFailure());
    }
  } catch (err) {
    yield put(deleteHotelWakeupFailure(err));
  }
}

/* function* fetchHotelWakeupAnswer(action) {
  try {
    const time = action.params.time.split(':');
    const day = moment(action.params.day, 'YYYY-MM-DD').toDate();
    day.setHours(+time[0]);
    day.setMinutes(+time[1]);
    day.setSeconds(0);
    day.setMilliseconds(0);
    const start = moment(day).subtract(2, 'm');
    const end = moment(day).add(2, 'm');
    const params = {
      extension: action.params.extension,
      start,
      end,
    };
    const res = yield call(
      PbxManager.retrieveWakeupAnswer.bind(PbxManager, params)
    );
    yield call(checkApiResponse,res);
    yield put(
      fetchHotelWakeupAnswerSuccess({
        id: +action.params.id,
        status: res.data.length === 0,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchHotelWakeupAnswerFailure(error));
  }
} */

export default function* rootSaga() {
  yield takeLatest(FETCH_HOTEL_AREA_PREFIXES_REQUEST, fetchHotelAreaPrefixes);
  yield takeLatest(FETCH_HOTEL_PRICES_REQUEST, fetchHotelPrices);
  yield takeLatest(SAVE_HOTEL_PRICES_REQUEST, saveHotelPrice);
  yield takeLatest(DELETE_HOTEL_PRICE_REQUEST, deleteHotelPrice);
  yield takeLatest(RESET_HOTEL_PRICES_REQUEST, resetHotelPrices);
  yield takeLatest(RESET_HOTEL_PRICES_SUCCESS, fetchHotelPrices);
  yield takeLatest(FETCH_HOTEL_CALLS_REQUEST, fetchHotelCalls);
  yield takeLatest(
    TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST,
    toggleHotelWakeupService
  );
  yield takeLatest(
    FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST,
    fetchHotelWakeupSettings
  );
  yield takeLatest(
    UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST,
    updateHotelWakeupSettings
  );
  yield takeLatest(FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST, fetchHotelActiveWakeups);
  // yield takeEvery(FETCH_HOTEL_WAKEUP_ANSWER_REQUEST, fetchHotelWakeupAnswer);
  yield takeLatest(
    FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST,
    fetchArchiveHotelWakeups
  );
  yield takeLatest(SAVE_HOTEL_WAKEUP_REQUEST, saveHotelWakeup);
  yield takeLatest(DELETE_HOTEL_WAKEUP_REQUEST, deleteHotelWakeup);
}
