export class VboxEnums {
  static Sex = {
    MALE: 'male',
    FEMALE: 'female',
  };

  static Language = {
    ITALIAN: 'italian',
    ENGLISH: 'english',
    FRENCH: 'french',
    GERMAN: 'german',
    SPANISH: 'spanish',
    PORTUGUESE: 'portuguese',
    RUSSIAN: 'russian',
    JAPANESE: 'japanese',
    CHINESE: 'chinese',
    ARABIC: 'arabic',
    INDIFFERENT: 'indifferent',
  };

  static Genre = {
    CLASSIC: 'classic',
    MODERN: 'modern',
    CHRISTMAS: 'christmas',
    NONE: 'none',
  };

  static GreetingMessageType = {
    SPEAKER: 'SPEAKER',
    UPLOAD: 'UPLOAD',
    TTS: 'TTS',
    REC: 'REC',
  };
}

const selectLanguage = (language) => {
  let languageFilter = '';
  switch (language) {
    case VboxEnums.Language.ITALIAN:
      languageFilter = 'italiana';
      break;
    case VboxEnums.Language.ENGLISH:
      languageFilter = 'inglese';
      break;
    case VboxEnums.Language.FRENCH:
      languageFilter = 'francese';
      break;
    case VboxEnums.Language.GERMAN:
      languageFilter = 'tedesco';
      break;
    case VboxEnums.Language.SPANISH:
      languageFilter = 'spagnolo';
      break;
    case VboxEnums.Language.PORTUGUESE:
      languageFilter = 'portoghese';
      break;
    case VboxEnums.Language.RUSSIAN:
      languageFilter = 'russa';
      break;
    case VboxEnums.Language.JAPANESE:
      languageFilter = 'giapponese';
      break;
    case VboxEnums.Language.CHINESE:
      languageFilter = 'cinese';
      break;
    case VboxEnums.Language.ARABIC:
      languageFilter = 'arabo';
      break;
    default:
      languageFilter = {};
  }
  return languageFilter;
};

export const voicesSelect = (allSounds, language) => {
  const sounds = {};
  const languageFilter = selectLanguage(language);

  sounds[language] = {};
  sounds[language].female = allSounds.filter((sound) =>
    sound.file.includes(`femminilelingua${languageFilter}`)
  );
  sounds[language].male = allSounds.filter((sound) =>
    sound.file.includes(`maschilelingua${languageFilter}`)
  );
  return sounds;
};

export const ARCHIVED_MESSAGES_PAGE_SIZE = 10;
