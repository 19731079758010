/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { DropTarget } from 'react-dnd';
import routes from '../routes/userRouting';
import Header from '../panels/Header';
import LeftColumn from '../panels/LeftColumn';
import RightColumn from '../panels/RightColumn';
import Loading from '../../components/Loading';
import GlobalErrorPage from './GlobalErrorPage';
import UserRoute from '../routes/UserRoute';
import ConfirmModal from '../../components/formControls/ConfirmModal';
import StickyList from '../sections/StickyNote/StickyList';
import TransferCustomDrag from '../panels/LeftColumn/TransferCustomDrag';
import Utils from '../../js/lib/utils';
import { updateStickyRequest } from '../../js/sticky/actions';
import ChatWindowsList from '../panels/Chat/ChatWindowsList';
import Footer from '../panels/Footer';
import { isAuthenticated, isTech } from '../../js/auth/selectors';
import { getLastUpdatePwd } from '../../js/me/selectors';
import UpdatePasswordModal from '../../components/UpdatePasswordModal';
import TechLoginModal from '../../components/TechLoginModal';
import ChangedGrantsModal from '../../components/ChangedGrantsModal';
import { setUiDimensions } from '../../js/ui/actions';
import ynConf from '../../conf';
import IntroModal from '../../components/IntroModal';
import ToastMessages from '../../components/ToastMessages';
import HelpModal from '../../components/HelpModal';
import JitsiVideocallExecPage from '../sections/Videocall/JitsiVideocallExecPage';

const MUST_CHANGE_PASSWORD_AFTER = 90 * 24 * 60 * 60 * 1000; // 90 giorni

const stickyTarget = {
  drop(props, monitor) {
    const { x, y } = monitor.getDifferenceFromInitialOffset();
    const oldSticky = monitor.getItem().stickyNote;
    const oldStickyTopPosition = parseInt(oldSticky.detail.top, 10);
    const oldStickyLeftPosition = parseInt(oldSticky.detail.left, 10);
    const newSticky = {
      ...oldSticky,
      detail: {
        ...oldSticky.detail,
        top: oldStickyTopPosition + y,
        left: oldStickyLeftPosition + x,
      },
    };
    props.updateSticky(oldSticky.id, newSticky);
  },
};

class MainPage extends Component {
  ynPage = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showTechModal: false,
      showIntroModal: true,
      showNewsModal: false,
    };
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize.bind(this));
    const googleScript = document.createElement('script');
    googleScript.id = 'googleMaps';
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${ynConf.googleKey}&libraries=places&callback=window.callbackGoogleMap`;
    window.document.body.appendChild(googleScript);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleKeyDown = (e) => {
    if (
      (e.ctrlKey && e.shiftKey && e.keyCode === 85) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 65)
    ) {
      this.setState({ showTechModal: true });
    }
  };

  toggleTechModal = () => {
    const { showTechModal } = this.state;
    this.setState({ showTechModal: !showTechModal });
  };

  toggleNewsModal = () => {
    const { showNewsModal } = this.state;
    this.setState({ showNewsModal: !showNewsModal });
  };

  closeIntroModal = (showNews) => {
    this.setState({ showIntroModal: false, showNewsModal: showNews });
  };

  handleWindowResize = () => {
    if (!this.ynPage.current) return;
    const { setDimensions } = this.props;
    setDimensions({
      ynPage: {
        width: this.ynPage.current.clientWidth,
        height: this.ynPage.current.clientHeight,
      },
    });
  };

  render() {
    const {
      lastUpdatePwd,
      location,
      error,
      loaded,
      connectDropTarget,
      isTechLogged,
      changedGrantsModal,
      showIntro,
    } = this.props;

    const { showTechModal } = this.state;

    const pwdExpired =
      lastUpdatePwd !== undefined &&
      lastUpdatePwd !== null &&
      lastUpdatePwd + MUST_CHANGE_PASSWORD_AFTER < Date.now();

    const pwdChanged = lastUpdatePwd === null;

    return connectDropTarget(
      <div className="yn-page" onKeyDown={this.handleKeyDown} ref={this.ynPage}>
        {!loaded ? (
          <Loading />
        ) : error ? (
          <GlobalErrorPage />
        ) : (
          ((!pwdExpired && !pwdChanged) || isTechLogged) && (
            <>
              <Header />
              <LeftColumn location={location} />

              <div
                className="yn-main p-0"
                style={{
                  width: 'calc(100% - 40em)',
                  display:
                    location.pathname.indexOf('/videocall/exec') !== 0
                      ? 'none'
                      : null,
                }}
              >
                <JitsiVideocallExecPage location={location} />
              </div>
              <div
                className="yn-main p-0"
                style={{
                  width: 'calc(100% - 40em)',
                  display:
                    location.pathname.indexOf('/videocall/exec') === 0
                      ? 'none'
                      : null,
                }}
              >
                <Switch>
                  {routes.map((route) => (
                    <UserRoute
                      location={location}
                      exact={!route.notExact}
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      grants={route.grants}
                    />
                  ))}
                  <Redirect exact path="/" to="/calls" />
                  <UserRoute component={GlobalErrorPage} code={404} />
                </Switch>
              </div>
              <RightColumn />
              <StickyList />
              <ChatWindowsList />
              <Footer />
              <ConfirmModal />
              <TransferCustomDrag />
            </>
          )
        )}
        <UpdatePasswordModal
          show={(pwdExpired || pwdChanged) && !showTechModal && !isTechLogged}
          pwdExpired={pwdExpired}
          pwdChanged={pwdChanged}
        />
        <TechLoginModal
          show={showTechModal && !isTechLogged}
          onCancel={this.toggleTechModal}
        />
        <ChangedGrantsModal show={changedGrantsModal} />
        {showIntro && this.state.showIntroModal && (
          <IntroModal
            isOpen={showIntro && this.state.showIntroModal}
            onClose={this.closeIntroModal}
          />
        )}
        <HelpModal
          fileName="latest-news"
          title=" "
          isOpen={this.state.showNewsModal}
          onToggle={this.toggleNewsModal}
        />
        <ToastMessages />
        <audio id="localaudio" width="0" height="0" autoPlay muted="muted" />
        <audio id="remoteaudio" width="0" height="0" autoPlay />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastUpdatePwd: getLastUpdatePwd(state),
    isLogged: isAuthenticated(state),
    isTechLogged: isTech(state),
    loaded: state.me.loaded && state.me.inited,
    error: !!state.me.error,
    changedGrantsModal: state.me.changedGrantsModal,
    showIntro: !state.settings.hideIntro,
  };
}

export default connect(mapStateToProps, {
  updateSticky: updateStickyRequest,
  setDimensions: setUiDimensions,
})(
  DropTarget(
    [Utils.DragAndDropTypes.stickyNote],
    stickyTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  )(MainPage)
);
