import { createSelector } from 'reselect';
import sortBy from 'lodash.sortby';
import findKey from 'lodash.findkey';
import { PhoneEnums } from '../phone/PhoneUtils';
import { PbxSettings } from '../phone/PbxSettingsUtils';
import { PresenceEnums } from './PresenceUtils';

const defaultList = [];
const defaultObject = {};

export const getUsersTotalQuantity = (state) => state.users.total;
export const getFetchUsersLoaded = (state) => state.users.fetchUsersLoaded;
export const getFetchUsersError = (state) => state.users.fetchUsersError;
export const getFetchUserLoaded = (state) => state.users.fetchUserLoaded;
export const getFetchUserError = (state) => state.users.fetchUserError;
export const getSaveUserLoaded = (state) => state.users.saveUserLoaded;
export const getSaveUserError = (state) => state.users.saveUserError;
export const getUsersById = (state) => state.users.byId;
export const getUsersIds = (state) => state.users.allIds;
export const getUsers = createSelector(
  [getUsersById, getUsersIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getUserById = (state, id) => state.users.byId[id] || defaultObject;
export const isUserFavorite = (state, idUser) =>
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')] &&
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')].members &&
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')].members
    .map((member) => member.type === 'U' && member.id)
    .indexOf(idUser) > -1;
export const getUserMainExtensionUsername = (state, userId) =>
  state.users.byId[userId] &&
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS
    ? state.users.byId[userId].mainExtensionUsername
    : state.users.byId[userId].username;
export const getUserWebrtcExtensionUsername = (state, userId) =>
  (state.users.byId[userId] &&
    state.users.byId[userId].others.map(
      (extension) => extension.type === 'WEBRTC' && extension.username
    )[0]) ||
  null;
export const getUserFromMainExtensionUsername = (state, username) =>
  Object.values(state.users.byId).filter(
    (user) => user.mainExtensionUsername === username
  );
export const getUserAvatar = (state, userId) =>
  getUserById(state, userId).avatar;
export const getOnlineUsers = (state) =>
  state.users.allIds.filter(
    (userId) =>
      state.users.byId[userId] &&
      !!state.users.byId[userId].status &&
      (!!state.users.byId[userId].status.yn[PresenceEnums.Device.WEB] ||
        !!state.users.byId[userId].status.yn[PresenceEnums.Device.MOBILE])
  );
export const isUserOnline = (state, userId) =>
  state.users.byId[userId] &&
  !!state.users.byId[userId].status &&
  (!!state.users.byId[userId].status.yn[PresenceEnums.Device.WEB] ||
    !!state.users.byId[userId].status.yn[PresenceEnums.Device.MOBILE]);
export const isUserOnlineWeb = (state, userId) =>
  state.users.byId[userId] &&
  !!state.users.byId[userId].status &&
  !!state.users.byId[userId].status.yn[PresenceEnums.Device.WEB];
export const isUserOnlineApp = (state, userId) =>
  state.users.byId[userId] &&
  !!state.users.byId[userId].status &&
  !!state.users.byId[userId].status.yn[PresenceEnums.Device.MOBILE];
export const getUsersIdsPaged = (state) => {
  const page = state.users.currentPage;
  const pageIds = state.users.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getUsersPaged = createSelector(
  [getUsersById, getUsersIdsPaged],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getCurrentPage = (state) => state.users.currentPage;
export const getIsPageFetched = (state, page) =>
  state.users.pages[page] !== undefined;
export const getUsersSelectOptions = createSelector(
  getUsers,
  (users) =>
    users &&
    sortBy(
      users
        .filter((user) => user.departmentFullname)
        .map((user) => ({
          value: user.id,
          label: user.departmentFullname,
        })),
      [(option) => option.label.toLowerCase()]
    )
);
export const isVideocallDnd = (state, userId) =>
  state.users.byId[userId] &&
  state.users.byId[userId].status &&
  state.users.byId[userId].status.videocallDnd;
export const getUserFullname = (state, userId) =>
  state.users.byId[userId] && state.users.byId[userId].fullname;
export const getUserVacationsDataById = (state, id) =>
  (state.users.byId[id] && state.users.byId[id].vacations) || defaultObject;
export const getUserPunchingsDataById = (state, id) =>
  (state.users.byId[id] && state.users.byId[id].punchings) || defaultObject;
export const getUserRegistryDataById = (state, id) =>
  (state.users.byId[id] && state.users.byId[id].hr) || defaultObject;
export const getFetchUserVacationsDataLoaded = (state) =>
  state.users.fetchUserVacationsDataLoaded;
export const getFetchUserVacationsDataError = (state) =>
  state.users.fetchUserVacationsDataError;
export const getFetchUserPunchingsDataLoaded = (state) =>
  state.users.fetchUserPunchingsDataLoaded;
export const getFetchUserPunchingsDataError = (state) =>
  state.users.fetchUserPunchingsDataError;
export const getFetchUserRegistryDataLoaded = (state) =>
  state.users.fetchUserRegistryDataLoaded;
export const getFetchUserRegistryDataError = (state) =>
  state.users.fetchUserRegistryDataError;
export const getSaveUserVacationsDataLoaded = (state) =>
  state.users.saveUserVacationsDataLoaded;
export const getSaveUserVacationsDataSuccess = (state) =>
  state.users.saveUserVacationsDataSuccess;
export const getSaveUserVacationsDataError = (state) =>
  state.users.saveUserVacationsDataError;
export const getSaveUserPunchingsDataLoaded = (state) =>
  state.users.saveUserPunchingsDataLoaded;
export const getSaveUserPunchingsDataSuccess = (state) =>
  state.users.saveUserPunchingsDataSuccess;
export const getSaveUserPunchingsDataError = (state) =>
  state.users.saveUserPunchingsDataError;
export const getSaveUserRegistryDataLoaded = (state) =>
  state.users.saveUserRegistryDataLoaded;
export const getSaveUserRegistryDataSuccess = (state) =>
  state.users.saveUserRegistryDataSuccess;
export const getSaveUserRegistryDataError = (state) =>
  state.users.saveUserRegistryDataError;
export const getFetchUserSuperviewersLoaded = (state) =>
  state.users.fetchUserSuperviewersLoaded;
export const getFetchUserSuperviewersError = (state) =>
  state.users.fetchUserSuperviewersError;
export const getSaveUserSuperviewersLoaded = (state) =>
  state.users.saveUserSuperviewersLoaded;
export const getSaveUserSuperviewersSuccess = (state) =>
  state.users.saveUserSuperviewersSuccess;
export const getSaveUserSuperviewersError = (state) =>
  state.users.saveUserSuperviewersError;
export const getUsersWithPhone = createSelector(getUsers, (users) =>
  users.filter(
    (user) =>
      user.mainExtensionNumber &&
      user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER
  )
);
export const getUserStatus = (state, id) =>
  (state.users.byId[id] &&
    state.users.byId[id].status &&
    state.users.byId[id].status.userStatus) ||
  defaultObject;

export const getUsersMainExtensionByUsername = createSelector(
  getUsersWithPhone,
  (users) => {
    const extensions = {};
    users.forEach((element) => {
      extensions[element.w] = element.mainExtensionNumber;
    });

    return extensions;
  }
);

export const getUsersMainExtensionByWebrtcExtension = createSelector(
  getUsersWithPhone,
  (users) => {
    const extensions = {};
    users
      .filter((el) => el.webrtcExtensionNumber)
      .forEach((el) => {
        extensions[el.webrtcExtensionNumber] = el.mainExtensionNumber;
      });

    return extensions;
  }
);

export const getUsersMainExtensionByOtherExtensions = createSelector(
  getUsersWithPhone,
  (users) => {
    const extensions = {};
    users
      //.filter((el) => el.webrtcExtensionNumber)
      .forEach((el) => {
        el.others.forEach((o) => {
          extensions[o.number] = el.mainExtensionNumber;
        });
      });

    return extensions;
  }
);
