import moment from 'moment';
import sortBy from 'lodash.sortby';
import {
  isCallNotificationEnabled,
  isFaxNotificationEnabled,
  isCalendarNotificationEnabled,
  isDataNotificationEnabled,
  isMailofficeNotificationEnabled,
  isVboxNotificationEnabled,
  showSticky,
  isPresencesNotificationEnabled,
  isTicketNotificationEnabled,
  isFileboxNotificationEnabled,
} from '../settings/selectors';
import { getStickyNotes } from '../sticky/selectors';
import { NotificationEnums } from './NotificationUtils';
import { getTotalUnreadMessages } from '../chat/selectors';

const defaultList = [];

/* Calls */
export const getWebNotificationsLostCalls = (state) =>
  (isCallNotificationEnabled(state) && state.notifications.web.calls.lost) ||
  defaultList;
export const getWebNotificationsCallsNumber = (state) =>
  isCallNotificationEnabled(state) && state.notifications.web.calls.lost.length;

export const getWebNotificationsIncomingCalls = (state) =>
  (isCallNotificationEnabled(state) &&
    state.notifications.web.calls.alerting) ||
  defaultList;
export const getDesktopNotificationsLostCalls = (state) =>
  (isCallNotificationEnabled(state) &&
    state.notifications.desktop.calls.allIds
      .map((id) => state.notifications.desktop.calls.byId[id])
      .filter(
        (call) => call.type === NotificationEnums.NotificationGroups.LOST_CALLS
      )) ||
  defaultList;
export const getDesktopNotificationsIncomingCalls = (state) =>
  (isCallNotificationEnabled(state) &&
    state.notifications.desktop.calls.allIds
      .map((id) => state.notifications.desktop.calls.byId[id])
      .filter(
        (call) =>
          call.type === NotificationEnums.NotificationGroups.INCOMING_CALLS
      )) ||
  defaultList;

/* Calendar */
const getNotificationsCalendar = (state, notifications) => {
  if (!isCalendarNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.CALENDAR)
    .map((o) => ({
      ...o,
      oldTitle:
        (state.calendar.byId[o.idEntity] &&
          state.calendar.byId[o.idEntity].title) ||
        '',
      modifiedBy:
        (state.users.byId[o.idUserAction] &&
          state.users.byId[o.idUserAction].departmentFullname) ||
        '',
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
const getNotificationsVacationCalendar = (state, notifications) => {
  if (!isPresencesNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.VACATION)
    .map((o) => ({
      ...o,
      modifiedBy:
        (state.users.byId[o.idUserAction] &&
          state.users.byId[o.idUserAction].departmentFullname) ||
        '',
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsCalendar = (state) => {
  let notifications = getNotificationsCalendar(
    state,
    state.notifications.web.calendar
  );
  notifications = notifications.concat(
    getNotificationsVacationCalendar(state, state.notifications.web.calendar)
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsCalendar = (state) => {
  let notifications = getNotificationsCalendar(
    state,
    state.notifications.archived.calendar.data
  );
  notifications = notifications.concat(
    getNotificationsVacationCalendar(
      state,
      state.notifications.archived.calendar.data
    )
  );
  return notifications;
};
export const getWebNotificationsCalendarNumber = (state) =>
  getWebNotificationsCalendar(state).length;

/* Contacts */
const getNotificationsContacts = (state, notifications) => {
  if (!isDataNotificationEnabled(state)) return defaultList;
  return notifications.map((o) => ({
    ...o,
    oldName:
      (state.contacts.listContacts &&
        state.contacts.listContacts.byId[o.idEntity] &&
        state.contacts.listContacts.byId[o.idEntity].fullname) ||
      '',
    modifiedBy:
      (state.users.byId[o.idUserAction] &&
        state.users.byId[o.idUserAction].departmentFullname) ||
      '',
    timestamp: moment(o.dateTime).valueOf(),
  }));
};
export const getWebNotificationsContacts = (state) => {
  const notifications = getNotificationsContacts(
    state,
    state.notifications.web.abook
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsContacts = (state) =>
  getNotificationsContacts(state, state.notifications.archived.abook.data);
export const getWebNotificationsContactsNumber = (state) =>
  getWebNotificationsContacts(state).length;

/* Admin: Users and groups */
const getNotificationsAdmin = (state, notifications) => {
  return notifications.map((o) => ({
    ...o,
    oldName:
      (o.idEntity &&
        state.users.byId[o.idEntity] &&
        state.users.byId[o.idEntity].fullname) ||
      '',
    modifiedBy:
      (state.users.byId[o.idUserAction] &&
        state.users.byId[o.idUserAction].departmentFullname) ||
      '',
    timestamp: moment(o.dateTime).valueOf(),
  }));
};
export const getWebNotificationsAdmin = (state) => {
  if (!isDataNotificationEnabled(state)) return defaultList;
  const notifications = getNotificationsAdmin(
    state,
    state.notifications.web && state.notifications.web.admin
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsAdmin = (state) => {
  if (!isDataNotificationEnabled(state)) return defaultList;
  return getNotificationsAdmin(
    state,
    state.notifications.archived && state.notifications.archived.admin.data
  );
};
export const getWebNotificationsAdminNumber = (state) =>
  getWebNotificationsAdmin(state).length;

/* Fax */
const getNotificationsFax = (state, notifications) => {
  if (!isFaxNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.FAX)
    .map((o) => ({
      ...o,
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsFax = (state) => {
  const notifications = getNotificationsFax(state, state.notifications.web.fax);
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsFax = (state) =>
  getNotificationsFax(state, state.notifications.archived.fax.data);
export const getWebNotificationsFaxNumber = (state) =>
  getWebNotificationsFax(state).length;

/* Mailoffice */
const getNotificationsMailoffice = (state, notifications) => {
  if (!isMailofficeNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.MAILOFFICE)
    .map((o) => ({
      ...o,
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsMailoffice = (state) => {
  const notifications = getNotificationsMailoffice(
    state,
    state.notifications.web.mailoffice
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsMailoffice = (state) =>
  getNotificationsMailoffice(
    state,
    state.notifications.archived.mailoffice.data
  );
export const getWebNotificationsMailofficeNumber = (state) =>
  Object.keys(getWebNotificationsMailoffice(state)).length;

/* Vbox */
const getNotificationsMeVbox = (state, notifications) => {
  if (!isVboxNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.MEVBOX)
    .map((o) => ({
      ...o,
      timestamp: moment(o.dateTime).valueOf(),
      sender:
        o.senderId && state.users.byId[o.senderId]
          ? state.users.byId[o.senderId].departmentFullname
          : o.senderNumber,
    }));
};
export const getWebNotificationsMeVbox = (state) => {
  const notifications = getNotificationsMeVbox(
    state,
    state.notifications.web.mevbox
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsMeVbox = (state) =>
  getNotificationsMeVbox(state, state.notifications.archived.mevbox.data);
export const getWebNotificationsMeVboxNumber = (state) =>
  getWebNotificationsMeVbox(state).length;

const getNotificationsPbxVbox = (state, notifications) => {
  if (!isVboxNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.PBXVBOX)
    .map((o) => ({
      ...o,
      timestamp: moment(o.dateTime).valueOf(),
      sender:
        o.senderId && state.users.byId[o.senderId]
          ? state.users.byId[o.senderId].departmentFullname
          : o.senderNumber,
      creation: o.creation
        ? moment(o.creation).format(state.settings.dateFormat)
        : null,
    }));
};
export const getWebNotificationsPbxVbox = (state) => {
  const notifications = getNotificationsPbxVbox(
    state,
    state.notifications.web.pbxvbox
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsPbxVbox = (state) =>
  getNotificationsPbxVbox(state, state.notifications.archived.pbxvbox.data);
export const getWebNotificationsPbxVboxNumber = (state) =>
  getWebNotificationsPbxVbox(state).length;

export const isArchivedFetch = (state, topic) =>
  state.notifications.archived[topic.toLowerCase()].fetched;

export const getArchivedNotificationsNumber = (state, topic) =>
  state.notifications.archived[topic.toLowerCase()].count;

/* Validation */
const getNotificationsValidation = (state, notifications) => {
  if (!isPresencesNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.VALIDATION)
    .map((o) => ({
      ...o,
      modifiedBy:
        (state.users.byId[o.idUserAction] &&
          state.users.byId[o.idUserAction].departmentFullname) ||
        '',
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsValidation = (state) => {
  const notifications = getNotificationsValidation(
    state,
    state.notifications.web.validation
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsValidation = (state) =>
  getNotificationsValidation(
    state,
    state.notifications.archived.validation.data
  );
export const getWebNotificationsValidationNumber = (state) =>
  getWebNotificationsValidation(state).length;

/* Punching */
const getNotificationsPunching = (state, notifications) => {
  if (!isPresencesNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.PUNCHING)
    .map((o) => ({
      ...o,
      modifiedBy:
        (state.users.byId[o.idUserAction] &&
          state.users.byId[o.idUserAction].departmentFullname) ||
        '',
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsPunching = (state) => {
  const notifications = getNotificationsPunching(
    state,
    state.notifications.web.punching
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsPunching = (state) =>
  getNotificationsPunching(state, state.notifications.archived.punching.data);
export const getWebNotificationsPunchingNumber = (state) =>
  getWebNotificationsPunching(state).length;

/* Sticky */
export const getWebNotificationsStickyNumber = (state) =>
  showSticky(state) ? 0 : getStickyNotes(state).length;

/* Ticket */
const getNotificationsTicket = (state, notifications) => {
  if (!isTicketNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.TICKET)
    .map((o) => ({
      ...o,
      modifiedBy:
        o.associationId && o.memberId !== state.me.idCustomer
          ? state.associations.associationsById
            ? state.associations.associationsById[o.associationId].members.find(
                (member) => member.id === o.memberId
              ).name
            : ''
          : (state.users.byId[o.idUserAction] &&
              state.users.byId[o.idUserAction].departmentFullname) ||
            '',
    }));
};
export const getWebNotificationsTicket = (state) => {
  const notifications = getNotificationsTicket(
    state,
    state.notifications.web.ticket
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsTicket = (state) =>
  getNotificationsTicket(state, state.notifications.archived.ticket.data);
export const getWebNotificationsTicketNumber = (state) =>
  getWebNotificationsTicket(state).length;
/* Filebox */
const getNotificationsFilebox = (state, notifications) => {
  if (!isFileboxNotificationEnabled(state)) return defaultList;
  return notifications
    .filter((o) => o.topic === NotificationEnums.NotificationsTopics.FILEBOX)
    .map((o) => ({
      ...o,
      madeBy:
        (state.users.byId[o.idUserAction] &&
          state.users.byId[o.idUserAction].departmentFullname) ||
        '',
      timestamp: moment(o.dateTime).valueOf(),
    }));
};
export const getWebNotificationsFilebox = (state) => {
  const notifications = getNotificationsFilebox(
    state,
    state.notifications.web.filebox
  );
  return sortBy(notifications, 'timestamp').reverse();
};
export const getArchivedNotificationsFilebox = (state) =>
  getNotificationsFilebox(state, state.notifications.archived.filebox.data);
export const getWebNotificationsFileboxNumber = (state) =>
  getWebNotificationsFilebox(state).length;
export const getLostVideocallNotifications = (state) =>
  state.notifications.web.videocalls.lost;

export const getTotalWebNotificationsNumber = (state) =>
  (getWebNotificationsCallsNumber(state) || 0) +
  (getWebNotificationsCalendarNumber(state) || 0) +
  (getWebNotificationsContactsNumber(state) || 0) +
  (getWebNotificationsAdminNumber(state) || 0) +
  (getWebNotificationsFaxNumber(state) || 0) +
  (getWebNotificationsMailofficeNumber(state) || 0) +
  (getWebNotificationsMeVboxNumber(state) || 0) +
  (getWebNotificationsPbxVboxNumber(state) || 0) +
  (getWebNotificationsValidationNumber(state) || 0) +
  (getWebNotificationsPunchingNumber(state) || 0) +
  (getWebNotificationsTicketNumber(state) || 0) +
  (getWebNotificationsStickyNumber(state) || 0) +
  (getWebNotificationsFileboxNumber(state) || 0) +
  (getLostVideocallNotifications(state).length || 0) +
  (getTotalUnreadMessages(state) || 0);
