import jsSHA from 'jssha';
import moment from 'moment';
import io from 'socket.io-client';
import { Promise } from 'bluebird';
import sortBy from 'lodash.sortby';
import filter from 'lodash.filter';
import { NethesisCommands } from './NethesisCommands';
import { JanusCommands } from './JanusCommands';
import { PhoneEnums } from './PhoneUtils';
import { PbxSettings } from './PbxSettingsUtils';
import { PhoneRulesEnums } from '../phoneRules/PhoneRulesUtils';
import api from '../api';
import Utils from '../lib/utils';
import { WAKEUP_IDENTIFIER } from '../hotel/HotelUtils';
import { reject } from 'lodash';

/**
 * @constructor
 */
export class Nethesis {
  constructor(data) {
    this.mainUsername = data.mainUsername;
    this.mainPassword = data.mainPassword;
    this.mainNumber = data.mainNumber;
    this.activeNumber = this.mainNumber;
    this.webrtcUsername = data.webrtcUsername;
    this.webrtcPassword = data.webrtcPassword;
    this.webrtcNumber = data.webrtcNumber;
    this.url = data.url;
    this.pbxLine = data.pbxLine;
    this.queuePrefixes = data.queuePrefixes;
    // collect data about queues
    this.ringGroups = {};
    if (data.ringGroups) {
      data.ringGroups
        .split(',')
        .map((group) => parseInt(group.trim(), 10))
        .concat(PbxSettings.DEFAULT_RING_GROUPS)
        .forEach((number) => {
          this.ringGroups[number] = {
            all: true,
          };
        });
    }
    this.authenticationToken = null;
    this.command = new NethesisCommands({
      url: this.url,
      username: this.mainUsername,
    });
    this.queue = {};
    this.activeConference = false;
    // keep a reference of parked number that has picked up (to prevent asterisk bug)
    this.pickedupParkingNumber = null;
    this.lastCallData = {};
    this.lastUserData = {};
    this.activeAttendedTransfer = false;
    this.attendedTransferTimeout = null;
    this.isConferenceStarting = false;
    this.conferenceInterval = null;
    this.webrtcStarted = false;
    this.webrtcRegistered = false;
    this.webrtcInterval = null;
    this.janusCommand = null;
    if (this.webrtcUsername && this.webrtcNumber && this.webrtcPassword) {
      this.janusCommand = new JanusCommands({
        url: this.url,
        username: this.webrtcUsername,
        number: this.webrtcNumber,
        password: this.webrtcPassword,
      });
    }
  }

  login = () =>
    this.command
      .login(this.mainPassword)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch((response) => {
        if (response.status === 401 && response.headers['www-authenticate']) {
          // calculate HMAC-SHA1
          const digest = response.headers['www-authenticate'].replace(
            'Digest ',
            ''
          );
          const hashValue = `${this.mainUsername}:${this.mainPassword}:${digest}`;
          // eslint-disable-next-line new-cap
          const shaObj = new jsSHA('SHA-1', 'TEXT');
          shaObj.setHMACKey(this.mainPassword, 'TEXT');
          shaObj.update(hashValue);
          this.authenticationToken = shaObj.getHMAC('HEX');
          this.command.setAuthenticationToken(this.authenticationToken);
          return PhoneEnums.CallsActionsResults.ok;
          // TODO start ping timeout ???
          /* if (yn.conf.nethesis.ping.enabled) {
            _this._pingTimeout && clearInterval(_this._pingTimeout);
            _this._pingTimeout = setInterval(function() {
              _this._command.ping();
            }, yn.conf.nethesis.ping.timeout);
          } */
        }
        return PhoneEnums.CallsActionsResults.ko;
      });

  logout = () =>
    this.command
      .logout()
      .then(() => {
        this.authenticationToken = null;
        this.command.setAuthenticationToken(null);
        if (this.socket) {
          this.socket.disconnect();
        }
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  retrieveUserData = (userRow) => {
    if (userRow.exten === 'anonymous') {
      return null;
    }
    const userData = {
      number: userRow.exten,
      username: userRow.username || userRow.name,
      // pbxUsername: userRow.username,
      dnd: userRow.dnd ? PhoneEnums.DndStatus.ON : PhoneEnums.DndStatus.OFF,
      device: userRow.sipuseragent,
    };
    if (userRow.cfVm) {
      // set voicemail flag
      userData.pbxSettings = {
        active: true,
        status: PhoneRulesEnums.SettingsActions.vbox,
      };
    } else if (userRow.cf) {
      // set call forwarding flag
      userData.pbxSettings = {
        active: true,
        status: PhoneRulesEnums.SettingsActions.forward,
      };
    }
    switch (userRow.status) {
      case 'online':
        userData.status = PhoneEnums.UserStatuses.available;
        break;
      case 'busy':
        userData.status = PhoneEnums.UserStatuses.calling;
        break;
      case 'onhold':
        userData.status = PhoneEnums.UserStatuses.calling;
        userData.isHold = true;
        break;
      case 'ringing':
      case 'busy_ringing':
        userData.status = PhoneEnums.UserStatuses.ringing;
        break;
      case 'offline':
      default:
        userData.status = PhoneEnums.UserStatuses.unavailable;
        break;
    }
    // check if it is a callback call
    if (
      userData.status === PhoneEnums.UserStatuses.ringing &&
      Object.keys(userRow.conversations).length === 0
    ) {
      userData.selfCall = true;
    }
    return userData;
  };

  compareUserData = (a, b) => {
    if (!a || !b) {
      return false;
    }
    return (
      a.number === b.number &&
      a.username === b.username &&
      a.dnd === b.dnd &&
      a.status === b.status &&
      ((!a.pbxSettings && !b.pbxSettings) ||
        (a.pbxSettings &&
          b.pbxSettings &&
          a.pbxSettings.active === b.pbxSettings.active &&
          a.pbxSettings.status === b.pbxSettings.status))
    );
  };

  retrieveDefaultDevice = () =>
    this.command
      .retrieveDefaultDevice()
      .then((type) => type)
      .catch(() => null);

  // sometimes, with webrtc, for incoming calls in extenUpdate there is for physical number a conversation with same id of webrtc number's but the direction is OUT
  skippingWebrtcUpdate = (call, number) =>
    this.webrtcRegistered &&
    number === this.mainNumber &&
    this.lastCallData[this.webrtcNumber] &&
    this.lastCallData[this.webrtcNumber].callid === call.callid &&
    this.lastCallData[this.webrtcNumber].direction ===
      PhoneEnums.Direction.In &&
    call.direction === PhoneEnums.Direction.Out;

  compareCallData = (a, b) => {
    if (!a || !b) {
      return false;
    }
    return (
      a.number === b.number &&
      a.status === b.status &&
      a.callid === b.callid &&
      a.calling === b.calling &&
      a.called === b.called &&
      a.direction === b.direction &&
      a.conference === b.conference &&
      a.recording === b.recording
    );
  };

  retrieveConversationData = (userData, conversationData) => {
    // TODO frontend side
    /* if (conversationData.counterpartNum === "<unknown>") {
      conversationData.counterpartNum = amb.language.phone_external_number;
    } */
    let callStatus;
    /* AL 07/08/2019 added       
      conversationData.direction === 'out' && userData.status === 'ringing'
      to manage direction of 302 when 301 is calling 302 by callback (current nethesis version put direction=out also for 302) 
      AL 02/10/2019 added also conversationData.owner === conversationData.counterpartNum because it is a callback only in that case
      (to prevent to not to show direct transfer call in "calls in progress" because it is seen as a callback)  
    */
    const isCallbackCall =
      conversationData.direction === 'out' &&
      userData.status === PhoneEnums.UserStatuses.ringing &&
      conversationData.owner === conversationData.counterpartNum;
    const direction =
      conversationData.direction === 'out' &&
      userData.status === PhoneEnums.UserStatuses.ringing
        ? 'in'
        : conversationData.direction;
    const called =
      direction === 'out'
        ? conversationData.counterpartNum
        : conversationData.owner;
    let calling =
      direction === 'in'
        ? conversationData.counterpartNum
        : conversationData.owner;
    let fromQueueName;
    if (
      conversationData.throughQueue &&
      direction === 'in' &&
      conversationData.counterpartNum !== conversationData.counterpartName
    ) {
      fromQueueName = conversationData.counterpartName
        .replace(`:${conversationData.counterpartNum}`, '')
        .replace(conversationData.counterpartNum, '');
    }
    // if called = calling = mynumber and there is a pickedup parked number, it is an asterisk bug
    if (
      called === calling &&
      called === this.activeNumber &&
      this.pickedupParkingNumber
    ) {
      calling = this.pickedupParkingNumber;
    }
    if (userData.status === PhoneEnums.UserStatuses.ringing) {
      callStatus = PhoneEnums.CallsStatuses.alerting;
    } else if (userData.status === PhoneEnums.UserStatuses.calling) {
      if (userData.isHold) {
        callStatus = PhoneEnums.CallsStatuses.hold;
      } else if (conversationData.connected) {
        // if called = calling, there is callback so the call is not already active
        callStatus =
          called === calling
            ? PhoneEnums.CallsStatuses.calling
            : PhoneEnums.CallsStatuses.active;
      } else {
        // with userData.status === PbxUtils.userStatuses.calling, it is always an out call (do not check direction because it is wrong for external numbers)
        callStatus = PhoneEnums.CallsStatuses.alerting;
      }
    } else if (userData.status === PhoneEnums.UserStatuses.available) {
      callStatus = PhoneEnums.CallsStatuses.none;
    }

    const isInConference =
      userData.status === PhoneEnums.UserStatuses.calling &&
      conversationData.inConference; /* ||
        conversationData.id.indexOf("DAHDI") === 0 */
    const callData = {
      number: userData.number,
      username: userData.username,
      status: callStatus,
      callid: conversationData.id,
      calling,
      called,
      direction:
        /* PF 24/07/2019 added 
      
      conversationData.owner === conversationData.counterpartNum ||

      to manage callbacks and similar issues such as picking calls */
        conversationData.owner === conversationData.counterpartNum ||
        direction === 'in'
          ? PhoneEnums.Direction.In
          : PhoneEnums.Direction.Out,
      conference: isInConference,
      recording: conversationData.recording !== 'false',
      timestamp: new Date(
        new Date().getTime() - conversationData.duration * 1000
      ),
      callback: isCallbackCall,
      linkedId: conversationData.linkedId,
      destChannel: conversationData.chDest
        ? conversationData.chDest.channel
        : null,
      fromQueueName,
      usedDevice:
        callStatus === PhoneEnums.CallsStatuses.active ? userData.device : null,
    };
    // if call data is the same of the previous time, do not raise again the event
    if (
      callData &&
      !this.skippingWebrtcUpdate(callData, userData.number) &&
      !this.compareCallData(callData, this.lastCallData[userData.number])
    ) {
      this.lastCallData[userData.number] = {
        ...callData,
      };
      return {
        /* call: isInConference ? null : callData,
        conference: isInConference ? callData : null */
        call: callData,
      };
    }
    return null;
  };

  parseSingleExtensionData = (extensionData /* init */) => {
    if (!extensionData) {
      return {
        user: null,
        calls: [],
      };
    }
    let userData = this.retrieveUserData(extensionData);
    if (!userData || !userData.username) {
      return {
        user: null,
        calls: [],
      };
    }
    const calls = [];
    // let conferences = [];
    Object.values(extensionData.conversations).forEach((conversation) => {
      const conversationData = this.retrieveConversationData(
        userData,
        conversation
      );
      if (conversationData) {
        if (conversationData.call) {
          calls.push(conversationData.call);
        }
        /* if (conversationData.conference) {
          conferences.push(conversationData.conference);
        } */
      }
    });
    // if user data is the same of the previous time, do not raise again the event
    if (
      (userData &&
        !this.compareUserData(userData, this.lastUserData[userData.number])) ||
      calls.length > 0 /* ||
        conferences.length > 0 */
    ) {
      this.lastUserData[userData.number] = {
        ...userData,
      };
    } else {
      userData = {};
    }
    return {
      user: userData,
      calls,
      // conferences
    };
  };

  parseExtensionsData = (extensions, init) => {
    if (!extensions || Object.keys(extensions).length === 0) {
      return {
        user: null,
        calls: [],
      };
    }
    const users = [];
    let calls = [];
    // let conferences = [];
    Object.values(extensions).forEach((extensionData) => {
      const response = this.parseSingleExtensionData(extensionData, init);
      if (response.user) {
        users.push(response.user);
      }
      if (response.calls && response.calls.length > 0) {
        calls = calls.concat(response.calls);
      }
      /* if (response.conferences && response.conferences.length > 0) {
        conferences = conferences.concat(response.conferences);
      } */
    });
    return {
      users,
      calls,
      // conferences
    };
  };

  parseSingleParkingData = (parkingData) => {
    if (!parkingData) {
      return null;
    }

    const added =
      parkingData.parkedCaller &&
      Object.keys(parkingData.parkedCaller).length > 0;
    /* if (
      added &&
      parkingData.parkedCaller.parkeeNum !== this.mainNumber &&
      parkingData.parkedCaller.parkeeNum !== this.webrtcNumber
    ) {
      return null;
    } */
    const responseData = {
      type: PhoneEnums.QueueType.PARK,
      id: parkingData.name,
      status: added
        ? PhoneEnums.QueueStatus.added
        : PhoneEnums.QueueStatus.removed,
      timestamp: added
        ? new Date(
            new Date().getTime() +
              (parkingData.parkedCaller.timeout - parkingData.timeout) * 1000
          )
        : new Date(),
      calling: added ? parkingData.parkedCaller.num : '',
      called: added ? parkingData.parkedCaller.num : '',
      owner: added ? parkingData.parkedCaller.parkeeNum : '',
    };

    if (added) {
      this.queue[responseData.id] = responseData;
    } else {
      delete this.queue[responseData.id];
    }
    return responseData;
  };

  parseParkingsData = (parkings) => {
    if (!parkings || Object.keys(parkings).length === 0) {
      return [];
    }
    return Object.values(parkings).map((parkingData) =>
      this.parseSingleParkingData(parkingData)
    );
  };

  parseSingleQueueData = (queueData) => {
    if (!queueData) {
      return [];
    }
    // this.queue is an object of object; there are n object for each different queue number
    // for each one there are n objects for waiting callers

    // check new calls in queue
    const queues = [];
    Object.keys(queueData.waitingCallers).forEach((key) => {
      // parse only new queue rows
      if (this.queue[queueData.queue] && this.queue[queueData.queue][key]) {
        return;
      }
      const called = `*${queueData.name.replace('Queue', '')}*`;
      const responseData = {
        type: PhoneEnums.QueueType.QUEUE,
        id: key,
        status: PhoneEnums.QueueStatus.added,
        position: queueData.waitingCallers[key].position,
        timestamp: new Date(
          new Date().getTime() - queueData.waitingCallers[key].waiting * 1000
        ),
        calling: queueData.waitingCallers[key].num,
        called,
        queuename: queueData.waitingCallers[key].queue,
      };
      if (!this.queue[queueData.queue]) {
        this.queue[queueData.queue] = {};
      }
      this.queue[queueData.queue][key] = responseData;
      queues.push(responseData);
    });
    // check removed calls in queue
    Object.keys(this.queue).forEach((key) => {
      if (key !== queueData.queue) {
        return;
      }
      Object.keys(this.queue[key]).forEach((queueId) => {
        if (
          this.queue[key][queueId].type === PhoneEnums.QueueType.QUEUE &&
          !queueData.waitingCallers[queueId]
        ) {
          const called = `*${queueData.name.replace('queue', '')}*`;
          const responseData = {
            type: PhoneEnums.QueueType.QUEUE,
            id: queueId,
            status: PhoneEnums.QueueStatus.removed,
            position: null,
            timestamp: new Date(),
            calling: null,
            called,
          };
          delete this.queue[key][queueId];
          queues.push(responseData);
        }
      });
    });
    return queues;
  };

  parseQueuesData = (queues, init) => {
    if (!queues || Object.keys(queues).length === 0) {
      return { queues: [], queuesData: [] };
    }
    let responseQueues = [];
    let responseQueuesData = [];
    Object.values(queues).forEach((queueData) => {
      responseQueues = responseQueues.concat(
        this.parseSingleQueueData(queueData)
      );
      responseQueuesData = [
        ...responseQueuesData,
        {
          id: queueData.queue,
          name:
            queueData.name !== `Queue${queueData.queue}`
              ? queueData.name
              : null,
          members: Object.values(queueData.members).map((m) => m.name),
        },
      ];
      if (init) {
        // collect data about queues with members
        this.ringGroups[queueData.queue] = {
          all: false,
          members: Object.keys(queueData.members),
        };
      }
    });
    return { queues: responseQueues, queuesData: responseQueuesData };
  };

  getInitialStatus = () => {
    let responseData;
    return this.command
      .getExtensionsStatus()
      .then((response) => {
        responseData = this.parseExtensionsData(response.data, true);
        return this.command.getParkingsStatus();
      })
      .then((response) => {
        responseData.parks = this.parseParkingsData(response.data);
        return this.command.getQueuesStatus();
      })
      .then((response) => {
        const queues = this.parseQueuesData(response.data, true);
        responseData.queues = queues.queues;
        responseData.queuesData = queues.queuesData;
        responseData.status = PhoneEnums.CallsActionsResults.ok;
        return responseData;
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
  };

  getStatusChanges = () => {
    this.socket = io(`https://${this.url}`, {
      transports: ['websocket'],
      autoConnect: true,
      forceNew: true,
      reconnection: false,
    });
    return new Promise((resolve) => {
      this.socket.on('connect', () => {
        resolve(this.socket);
      });
      this.socket.on('connect_error', () => {
        console.log('Phone ws connect error');
        resolve(null);
      });
      this.socket.on('connect_timeout', () => {
        console.log('Phone ws connect timeout');
        resolve(null);
      });
    });
  };

  retrieveExitCode = () => this.pbxLine;

  loginWebsocket = () => {
    this.socket.emit('login', {
      accessKeyId: this.mainUsername,
      token: this.authenticationToken,
    });
  };

  toggleDND = (status) => {
    const nethesisStatus =
      status === PhoneEnums.DndStatus.ON ? 'dnd' : 'online';
    return this.command
      .toggleDND(nethesisStatus)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  callNumber = (number) =>
    this.webrtcRegistered
      ? this.janusCommand
          .call(number)
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko)
      : this.command
          .call(number, this.activeNumber, 'extension')
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko);

  hangupCall = (callId) =>
    this.webrtcRegistered
      ? this.janusCommand.hangup().then(() => PhoneEnums.CallsActionsResults.ok)
      : this.command
          .hangupCall(callId, this.activeNumber, 'extension')
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko);

  declineCall = (callId, incomingCalls) => {
    if (this.webrtcRegistered) {
      // hangup also for physical phone
      if (incomingCalls) {
        incomingCalls.forEach((incomingCall) => {
          if (incomingCall.number === this.mainNumber) {
            this.command.hangupCall(
              incomingCall.callid,
              this.mainNumber,
              'extension'
            );
          }
        });
      }
      return this.janusCommand
        .decline()
        .then(() => PhoneEnums.CallsActionsResults.ok);
    }
    return this.command
      .hangupCall(callId, this.activeNumber, 'extension')
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  hangupChannel = (channel) =>
    this.command
      .hangupChannel(channel, this.activeNumber, 'extension')
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  // USED IN NETHESIS ONLY FOR WEBRTC
  holdCall = (/* callId */) =>
    this.webrtcRegistered
      ? this.janusCommand.hold().then(() => PhoneEnums.CallsActionsResults.ok)
      : PhoneEnums.CallsActionsResults.ko;

  // USED IN NETHESIS ONLY FOR WEBRTC
  unholdCall = (/* callId */) =>
    this.webrtcRegistered
      ? this.janusCommand.unhold().then(() => PhoneEnums.CallsActionsResults.ok)
      : PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN NETHESIS
  switchCalls = () => PhoneEnums.CallsActionsResults.ko;

  attendedTransfer = (callId, to) => {
    const number = to.indexOf('+') === 0 ? `00${to.substring(1)}` : to;
    return this.command
      .attendedTransfer(callId, this.activeNumber, number)
      .then(() => {
        // TODO manage this flag
        this.activeAttendedTransfer = true;
        this.attendedTransferTimeout = setTimeout(() => {
          this.activeAttendedTransfer = false;
        }, 30000);
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  blindTransfer = (callId, to) => {
    const number = to.indexOf('+') === 0 ? `00${to.substring(1)}` : to;
    return this.command
      .blindTransfer(callId, this.activeNumber, number)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  blindTransferParking = (parkId, to) => {
    const number = to.indexOf('+') === 0 ? `00${to.substring(1)}` : to;
    return this.command
      .blindTransferParking(parkId, number)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  // UNUSED IN NETHESIS
  queueTransfer = (/* queueId, to */) => PhoneEnums.CallsActionsResults.ko;

  queueAnswer = (queueId, queueName) =>
    this.command
      .pickupQueue(queueName, queueId, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  parkAnswer = (queueId) => {
    this.pickedupParkingNumber = this.queue[queueId]
      ? this.queue[queueId].called
      : null;
    return this.command
      .pickupParking(queueId, this.mainNumber) // changed to mainNumber from activeNumber on 17/09/2019
      .then(() => {
        this.pickedupParkingNumber = null;
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  parkCall = (number, callId) =>
    this.command
      .park(callId, this.activeNumber, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  pickupCall = (callId, calledNumber) =>
    this.command
      .pickup(callId, calledNumber, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  linkCalls = (activeCallId) =>
    // hang up current call, hold one automatically talks with the current call user
    this.command
      .hangupCall(activeCallId, this.activeNumber, 'extension')
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  muteCall = (callId) =>
    this.webrtcRegistered
      ? this.janusCommand
          .mute()
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko)
      : this.command
          .mute(callId, this.activeNumber)
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko);

  unmuteCall = (callId) =>
    this.webrtcRegistered
      ? this.janusCommand
          .unmute()
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko)
      : this.command
          .unmute(callId, this.activeNumber)
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => PhoneEnums.CallsActionsResults.ko);

  startConference = (number, callId) => {
    this.isConferenceStarting = true;
    return this.command
      .startConference(callId, number, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  endConference = (conferenceId) =>
    this.command
      .endConference(conferenceId)
      .then(() => {
        if (this.conferenceInterval) {
          clearInterval(this.conferenceInterval);
        }
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  joinConference = () =>
    this.command
      .joinConference(this.activeNumber)
      .then(
        () =>
          this.webrtcRegistered &&
          this.janusCommand
            .call(PbxSettings.JANUS_CONFERENCE_CODE)
            .then(() => PhoneEnums.CallsActionsResults.ok)
            .catch(() => PhoneEnums.CallsActionsResults.ko)
      )
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  hangupUserInConference = (extensionId, conferenceId) =>
    this.command
      .hangupUserInConference(extensionId, conferenceId)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  muteUserInConference = (userId, conferenceId) =>
    this.command
      .muteUserInConference(userId, conferenceId)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  unmuteUserInConference = (userId, conferenceId) =>
    this.command
      .unmuteUserInConference(userId, conferenceId)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  retrieveConferenceData = (owner) =>
    this.command
      .retrieveConferenceData(owner)
      .then((response) => ({
        status: PhoneEnums.CallsActionsResults.ok,
        data: response.data,
      }))
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));

  recordCall = (callId, users) => {
    const channel = callId.split('>')[0];
    return api.phone
      .startRecordingCall({
        channel,
        users,
      })
      .then((res) => {
        const error = Utils.checkApiError(res);
        if (error) throw error;
        return this.command
          .startRecording(callId, this.activeNumber)
          .then(() => PhoneEnums.CallsActionsResults.ok)
          .catch(() => {
            // if there was an error starting the recording, delete the row from db
            api.phone
              .deleteRecordingCall(channel)
              .then(() => PhoneEnums.CallsActionsResults.ko)
              .catch(() => PhoneEnums.CallsActionsResults.ko);
          });
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  stopRecordCall = (callId) => {
    return this.command
      .stopRecording(callId, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);
  };

  spyCall = (callId, number) =>
    this.command
      .spyCall(callId, number, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  intrudeCall = (callId, number) =>
    this.command
      .intrudeCall(callId, number, this.activeNumber)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  savePhoneRule = (rule) => {
    let event = rule.event || [];
    if (
      event.length === 0 &&
      rule.action === PhoneRulesEnums.SettingsActions.dnd
    ) {
      event = [PhoneRulesEnums.SettingsEvents.always];
    }

    const data = {
      enabled: rule.enabled,
      startTime: `${rule.timeFromHour}:${rule.timeFromMinute}:00`,
      endTime: `${rule.timeToHour}:${rule.timeToMinute}:00`,
      rule: {
        action: rule.action,
        event,
        forwardCalled: rule.forwardCalled,
        defaultCgo: rule.defaultCgo,
        numberCalling: rule.numberCalling,
        greetingId: rule.greetingId,
      },
      subject: rule.subject,
      user: rule.user,
      route: rule.route,
    };
    if (rule.days) {
      data.days = Object.values(rule.days);
    }
    if (rule.startDate) {
      data.startDate = rule.startDate;
    }
    if (rule.endDate) {
      data.endDate = rule.endDate;
    }
    if (rule.festivity) {
      data.festivity = rule.festivity;
    }
    if (rule.add) {
      return api.phoneRules
        .insertRule(data)
        .then((res) => {
          const error = Utils.checkApiError(res);
          if (error) throw error;
          return PhoneEnums.CallsActionsResults.ok;
        })
        .catch((err) => {
          return {
            status: PhoneEnums.CallsActionsResults.ko,
            message:
              (err.data && err.data.length > 0 && err.data[0]) || err.status,
          };
        });
    }
    return api.phoneRules
      .updateRule(data, rule.id)
      .then((res) => {
        const error = Utils.checkApiError(res);
        if (error) throw error;
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch((err) => ({
        status: PhoneEnums.CallsActionsResults.ko,
        message: (err.data && err.data.length > 0 && err.data[0]) || err.status,
      }));
  };

  saveUserSetting = (rule) => this.savePhoneRule(rule);

  retrieveUserSettings = (userId) => {
    return api.phoneRules
      .retrievePbxRules({ user: userId })
      .then((response) => {
        const error = Utils.checkApiError(response);
        if (error) throw error;
        const rules = response.data || [];
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          rules: rules.map((rule) => this.parseRule(rule)),
        };
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
  };

  deletePhoneRule = (rule) =>
    api.phoneRules
      .deleteRule(rule.id)
      .then((response) => {
        const error = Utils.checkApiError(response);
        if (error) throw error;
        return PhoneEnums.CallsActionsResults.ok;
      })
      .catch(() => PhoneEnums.CallsActionsResults.ko);

  deleteUserSetting = (rule) => this.deletePhoneRule(rule);

  loadUserGroupsCall = (numbers, start, end) =>
    new Promise((resolve, reject) => {
      let groupsRows = [];
      // search only among queues with all members (from admin panel) or where at least a number is a member
      const promises = Object.keys(this.ringGroups)
        .filter(
          (queue) =>
            this.ringGroups[queue].all ||
            numbers.some(
              (number) => this.ringGroups[queue].members.indexOf(number) >= 0
            )
        )
        .map(
          (ringGroup) =>
            new Promise((resolveRingGroup, rejectRingGroup) => {
              this.command
                .getCdrSwitchboard(start, end, ringGroup, true)
                .then((response) => {
                  groupsRows = groupsRows.concat(response.data.rows);
                  resolveRingGroup();
                })
                .catch((err) => rejectRingGroup(err));
            })
        );
      Promise.all(promises)
        .then(() => {
          // filter all retrieved rows to understand if they have to be shown (answered by me or not answered by everybody) or not
          const filteredRows = [];
          let candidateRow = null;
          groupsRows.sort((a, b) => b.linkedid - a.linkedid);
          for (let i = 0; i < groupsRows.length; i += 1) {
            const row = groupsRows[i];
            // if id is different from the candidate, push it into array
            if (candidateRow && row.linkedid !== candidateRow.linkedid) {
              filteredRows.push(candidateRow);
              candidateRow = null;
            }
            if (
              filteredRows.length > 0 &&
              filteredRows[filteredRows.length - 1].uniqueid === row.uniqueid
            ) {
              continue;
            }
            if (
              !groupsRows[i - 1] ||
              row.linkedid !== groupsRows[i - 1].linkedid
            ) {
              candidateRow = row;
            }
            // dstchannel include my row: if I answered insert into filtered rows otherwise it's a candidate (we have to check if noone answered)
            // eslint-disable-next-line no-loop-func
            numbers.forEach((number) => {
              if (
                row.dstchannel.indexOf(`PJSIP/${number}`) === 0 ||
                row.dstchannel.indexOf(`PJSIP/91${number}`) === 0 ||
                row.dstchannel.indexOf(`PJSIP/92${number}`) === 0 ||
                row.dstchannel.indexOf(`/${number}@from-queue`) > 0 ||
                row.dstchannel.indexOf(`/91${number}@from-queue`) > 0 ||
                row.dstchannel.indexOf(`/92${number}@from-queue`) > 0
              ) {
                if (row.disposition === 'ANSWERED') {
                  filteredRows.push(row);
                  candidateRow = null;
                }
              } else if (row.disposition === 'ANSWERED') {
                // check if user has answered; in this case erase candidate
                candidateRow = null;
              }
            });
          }
          if (candidateRow) {
            filteredRows.push(candidateRow);
          }
          resolve(filteredRows);
        })
        .catch((error) => reject(error));
    });

  populateLogRow = (logRow, records) => {
    let type;
    records = records || {};
    if (logRow.direction === 'out') {
      type = 'out';
    } else {
      type = logRow.disposition === 'ANSWERED' ? 'in' : 'lost';
    }
    // skip if it is wakeup call
    if (
      logRow.dcontext === 'sveglia' ||
      logRow.src === 'Sveglia' // yn.conf.nethesis.wakeupIdentifier
    ) {
      return null;
    }
    // skip if it is to vbox (duplicate row)
    if (logRow.dst.indexOf('vmu') === 0) {
      return null;
    }
    const record =
      records[logRow.channel] ||
      records[logRow.dstchannel] ||
      records[
        logRow.dstchannel.substring(0, logRow.dstchannel.lastIndexOf(';'))
      ] ||
      records[logRow.channel.substring(0, logRow.channel.lastIndexOf(';'))];
    const isRecorded = logRow.recordingfile || record;
    let called;
    let ringGroup = false;
    let queueGroup = false;

    // / check if the destination is a ring/queue group
    if (
      !isNaN(logRow.dst) &&
      Object.keys(this.ringGroups).indexOf(logRow.dst) >= 0
    ) {
      if (logRow.dstchannel.indexOf('PJSIP/') === 0) {
        called = logRow.dstchannel.split('-')[0].replace('PJSIP/', '');
        ringGroup = true;
      } else if (logRow.dstchannel.indexOf('Local/') === 0) {
        called = logRow.dstchannel.split('@')[0].replace('Local/', '');
        queueGroup = true;
      }
    } else {
      called = logRow.dst;
      if (type === 'out') {
        const exitCode = this.retrieveExitCode();
        if (exitCode && called.indexOf(exitCode) === 0) {
          called = called.substr(exitCode.length);
        }
      }
    }
    return {
      id: logRow.uniqueid,
      htmlId: logRow.uniqueid.replace('.', ''),
      datetime: new Date(
        logRow.time * 1000 +
          new Date(logRow.time * 1000).getTimezoneOffset() * 60000
      ),
      calling: logRow.cnum || logRow.src, // / for external call: cnum->extension, src->outgoing number
      callingCode: logRow.cnam.replace(logRow.cnum, ''),
      called,
      duration: logRow.duration,
      result: logRow.disposition === 'ANSWERED',
      ringGroup,
      queueGroup,
      type,
      isRecorded,
      recordedFile: record ? record.filename : null,
      dcontext: logRow.dcontext,
      queue: queueGroup || ringGroup ? logRow.dst : null,
      channel: logRow.channel,
      linkedId: logRow.linkedid,
    };
  };

  parseLogsList = (logs, filters, records, isHotelCdr, idCustomer) => {
    let parsedLogs = [];
    let logsId = {};
    const forwardingLogsId = [];
    const forwardedLogs = {};
    const ringQueueGroupsId = [];
    const answeredRingQueueGroupsId = {};
    // search from the last to the first to prevent double rows (when there is an attended transfer)
    for (let i = logs.length - 1; i >= 0; i -= 1) {
      // skip if it is out of date range
      const callDate = new Date(
        logs[i].time * 1000 +
          new Date(logs[i].time * 1000).getTimezoneOffset() * 60000
      );
      if (
        (filters.start && filters.start > callDate) ||
        (filters.end && filters.end < callDate)
      ) {
        continue;
      }
      // skip if source = dest -> callback first phase
      if (logs[i].src !== logs[i].dst) {
        const log = this.populateLogRow(logs[i], records);

        if (log && log.queue) {
          ringQueueGroupsId.push(log.linkedId);
        }
      }
    }
    // search from the last to the first to prevent double rows (when there is an attended transfer)
    for (let i = logs.length - 1; i >= 0; i -= 1) {
      // skip if it is out of date range
      const callDate = new Date(
        logs[i].time * 1000 +
          new Date(logs[i].time * 1000).getTimezoneOffset() * 60000
      );
      if (
        (filters.start && filters.start > callDate) ||
        (filters.end && filters.end < callDate)
      ) {
        continue;
      }
      // skip if source = dest -> callback first phase
      if (logs[i].src !== logs[i].dst) {
        const log = this.populateLogRow(logs[i], records);

        if (log && log.queue) {
          if (log.result) {
            answeredRingQueueGroupsId[log.linkedId] = log.called;
          }
        } else if (log && log.channel.indexOf('from-queue') > 0) {
          if (ringQueueGroupsId.indexOf(log.linkedId) >= 0 && (!answeredRingQueueGroupsId[log.linkedId] || answeredRingQueueGroupsId[log.linkedId] === log.called)) {
            if (log.result) {
              answeredRingQueueGroupsId[log.linkedId] = log.called;
            }
            parsedLogs = parsedLogs.map((parsedLog) => {
              if (parsedLog.linkedId !== log.linkedId) {
                return parsedLog;
              }
              return {
                ...parsedLog,
                dcontext: log.dcontext || parsedLog.dcontext,
                duration:
                  !parsedLog.duration || (log.duration && log.result)
                    ? log.duration
                    : parsedLog.duration,
              };
            });
            continue;
          } else if (
            log.channel.indexOf(`Local/${log.called}@from-queue`) >= 0 &&
            logs[i].disposition !== 'ANSWERED' &&
            // TODO REMOVE WHEN NETHESIS FIX FOR THIS CUSTOMER!!!
            (!idCustomer || (idCustomer === 2003 && ringQueueGroupsId.indexOf(log.linkedId))) >= 0
          ) {
            // keep only if it was redirect from another extension
            continue;
          }
        }
        if (
          log &&
          (!logsId[log.id] ||
            logsId[log.id] !== logs[i].type ||
            (log.type === 'in' && logsId[log.id] === 'in'))
        ) {
          // this id has been linked from another call before it -> it's the case of a switched call
          if (forwardingLogsId.indexOf(log.id) >= 0) {
            log.type = 'switched';
          }
          if (logs[i].direction === 'out') {
            // the linked call has found before this one -> it's a case of trasferring
            if (logsId[logs[i].linkedid]) {
              log.type = 'transferred';
            } else if (logs[i].linkedid !== logs[i].uniqueid) {
              // we are in a switched case, add the linked id to the array
              forwardingLogsId.push(logs[i].linkedid);
              // add the log in this json to insert it AFTER the linked call
              if (forwardedLogs[logs[i].linkedid]) {
                forwardedLogs[logs[i].linkedid].push(log);
              } else {
                forwardedLogs[logs[i].linkedid] = [log];
              }
              logsId = { ...logsId, [log.id]: log.type };
              continue;
            }
          }
          if (
            logsId[log.id] &&
            logsId[log.id] === log.type &&
            log.type !== 'in'
          ) {
            continue;
          }
          if (!logsId[log.id] || logsId[log.id] !== 'in') {
            logsId = { ...logsId, [log.id]: log.type };
          }
          if (
            log.type === 'out' ||
            log.type === 'switched' ||
            log.type === 'transferred'
          ) {
            if (filters.out || isHotelCdr) {
              if (log.type === 'transferred') {
                if (log.result) {
                  // AL 23/10/19 answered call from and to webrtc is seen twice (one transferred and one not answered)
                  // if there is another call with the same id (for instance on a different channel SIP/webrtc)
                  // and not answered it could win
                  const sameIdFound = parsedLogs.some(
                    (l) => l.id === log.id && !l.result
                  );
                  if (sameIdFound) {
                    parsedLogs = parsedLogs.filter(
                      (l) => l.id !== log.id || l.result
                    );
                    log.type = 'out';
                  }
                } else {
                  // AL 07/11/19 (ticket 3872) it happens that there are 2 rows in cdr: one answered and the same one transferred
                  const sameIdFound = parsedLogs.some(
                    (l) => l.id === log.id && l.result
                  );
                  if (sameIdFound) {
                    continue;
                  }
                }
              }
              parsedLogs.unshift(log);
              if (log.type === 'switched' && forwardedLogs[log.id]) {
                // for switched calls, insert linking calls after the linked one
                parsedLogs = forwardedLogs[log.id].concat(parsedLogs);
              }
            }
          } else if (log.type === 'in') {
            // if there is another call with the same id (for instance on a different channel SIP/webrtc)
            // and type lost, we exclude it because the "in/answered" call wins
            parsedLogs = parsedLogs.filter(
              (l) => !(l.id === log.id && l.type === 'lost')
            );
            parsedLogs.forEach((parsedLog) => {
              if (parsedLog.id === log.id) {
                if (
                  parsedLog.called === log.called &&
                  parsedLog.calling === log.calling
                ) {
                  log.type = 'inParking';
                } else {
                  parsedLog.type = 'inTransferred';
                  log.type = 'inTransferring';
                }
              }
            });
            if (filters.in) {
              parsedLogs.unshift(log);
            }
          } else if (filters.lost) {
            // we take only lost calls that have not a answered call with the same id
            // (for instance on a different channel SIP/webrtc)
            if (!logsId[log.id] || logsId[log.id] !== 'in') {
              parsedLogs.unshift(log);
            }
          }
        }
      }
    }
    // skip if isHotel and call is among extensions -> dcontext= "ext-local"
    if (isHotelCdr) {
      parsedLogs = parsedLogs.filter(
        (log) => log.dcontext !== 'ext-local' && !log.queueGroup
      );
    }
    return parsedLogs;
  };

  retrieveMeCdr = (filters, options) => {
    return this.retrieveCdr(filters, options);
  };

  retrieveHotelCdr = (filters, options) => {
    if (!filters.searchingUsers || filters.searchingUsers.length === 0) {
      return { status: PhoneEnums.CallsActionsResults.ko };
    }
    return this.retrieveCdr({ ...filters, isHotelCdr: true }, options);
  };

  retrieveWakeups = (filters) => {
    if (
      !filters.archive &&
      !filters.active &&
      filters.searchingUsers.length === 0 &&
      filters.users.length === 0
    )
      return { status: PhoneEnums.CallsActionsResults.ko };

    if (filters.active) {
      return this.command
        .loadWakeups()
        .then((res) => {
          const wakeups = [];
          res.forEach((wakeup) => {
            const dateTime = moment.unix(
              wakeup.split('-')[1].replace('.call', '')
            );
            if (
              (filters.searchingUsers.length === 0 ||
                filters.searchingUsers.filter(
                  (user) => user.number === wakeup.split('-')[0]
                ).length > 0) &&
              (!filters.day || filters.day === dateTime.format('YYYY-MM-DD'))
            ) {
              wakeups.push({
                id: wakeup,
                extension: wakeup.split('-')[0],
                time: dateTime.format('HH:mm'),
                schedule: { d: dateTime.format('YYYY-MM-DD') },
                unixDateTime: dateTime,
              });
            }
          });

          return {
            status: PhoneEnums.CallsActionsResults.ok,
            data: sortBy(wakeups, 'unixDateTime').reverse(),
          };
        })
        .catch(() => {
          return { status: PhoneEnums.CallsActionsResults.ko };
        });
    }
    if (filters.archive) {
      const extensions =
        filters.users.length > 0
          ? filter(filters.searchingUsers, (o) => {
              return filters.users.indexOf(o.id) > -1;
            })
          : filters.searchingUsers;

      return this.retrieveCdr({
        start: filters.startDay,
        end: filters.endDay,
        searchingUsers: extensions,
        isWakeupCdr: true,
      })
        .then((res) => {
          const wakeups = [];
          let candidateRow = null;
          res.data.forEach((wakeup) => {
            const dateTime = moment.unix(wakeup.time).utc();
            if (candidateRow) {
              if (wakeup.uniqueid !== candidateRow.id) {
                wakeups.push(candidateRow);
                candidateRow = null;
              } else if (wakeup.disposition === 'ANSWERED') {
                candidateRow = {
                  id: wakeup.uniqueid,
                  extension: wakeup.dst,
                  time: dateTime.format('HH:mm'),
                  schedule: { d: dateTime.format('YYYY-MM-DD') },
                  rung: 'OK',
                  answer: wakeup.disposition === 'ANSWERED',
                };
              }
            } else {
              candidateRow = {
                id: wakeup.uniqueid,
                extension: wakeup.dst,
                time: dateTime.format('HH:mm'),
                schedule: { d: dateTime.format('YYYY-MM-DD') },
                rung: 'OK',
                answer: wakeup.disposition === 'ANSWERED',
              };
            }
          });
          if (candidateRow) {
            wakeups.push(candidateRow);
          }
          return { status: PhoneEnums.CallsActionsResults.ok, data: wakeups };
        })
        .catch(() => {
          return { status: PhoneEnums.CallsActionsResults.ko };
        });
    }
  };

  saveWakeup = (wakeup) => {
    const save = (data) =>
      api.hotel
        .getHotelWakeupSettings()
        .then((response) => {
          const promises = data.extensions.map((extension) => {
            const settings = response.data;
            return data.schedules.map((schedule) => {
              const params = {
                extension,
                date: moment(schedule).format('YYYYMMDD'),
                time: `${data.time.split(':')[0]}:${data.time.split(':')[1]}`,
                maxRetries: settings.retries,
                retryTime: settings.retryTime,
                waitTime: settings.waitingTime,
              };
              return new Promise((resolve, reject) => {
                this.command
                  .saveWakeup(params)
                  .then(() => resolve())
                  .catch((err) => reject(err));
              });
            });
          });

          return Promise.all(promises)
            .then(() => {
              return {
                status: PhoneEnums.CallsActionsResults.ok,
              };
            })
            .catch(() => {
              return { status: PhoneEnums.CallsActionsResults.ko };
            });
        })
        .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));

    if (wakeup.id) {
      return this.command
        .removeWakeup(wakeup.id)
        .then(() => {
          return save(wakeup);
        })
        .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
    }
    return save(wakeup);
  };

  deleteWakeup = (id) =>
    this.command
      .removeWakeup(id)
      .then((response) => {
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          data: response.data,
        };
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));

  retrieveOthersCdr = (filters, options) => {
    if (
      (!filters.searchingUsers || filters.searchingUsers.length === 0) &&
      (!filters.searchingContacts || filters.searchingContacts.length === 0)
    ) {
      return { status: PhoneEnums.CallsActionsResults.ko };
    }
    return this.retrieveCdr(filters, options);
  };

  retrieveCdr = (filters, options) => {
    options = options || {};
    const start = filters.start
      ? moment(filters.start).format('YYYYMMDD')
      : moment(new Date(1970, 0, 1)).format('YYYYMMDD');
    const end = filters.end
      ? moment(filters.end).format('YYYYMMDD')
      : moment().format('YYYYMMDD');
    const extraFilters = [];
    //extraFilters.push('limit=500');
    if (filters.isHotelCdr || (filters.out && !(filters.in || filters.lost))) {
      extraFilters.push('direction=out');
    }
    if (!filters.out && (filters.in || filters.lost)) {
      extraFilters.push('direction=in');
    }
    let filterpath = null;
    if (filters.isWakeupCdr) filterpath = WAKEUP_IDENTIFIER;
    let logs = [];
    let recordingCalls = [];
    if (
      (!filters.searchingUsers || !filters.searchingUsers.length) &&
      filters.searchingContacts
    ) {
      const promises = filters.searchingContacts.map(
        (contact) =>
          new Promise((resolve, reject) => {
            this.command
              .getCdrSwitchboard(start, end, contact, false)
              .then((response) => {
                logs = logs.concat(response.data.rows);
                logs.sort((a, b) => b.time - a.time);
                Promise.resolve();
              })
              .then(() => resolve())
              .catch((err) => reject(new Error(err)));
          })
      );
      return Promise.all(promises)
        .then(() => {
          const finalLogs = this.parseLogsList(
            logs,
            filters,
            recordingCalls,
            filters.isHotelCdr,
            options && options.customerId
          );
          return {
            status: PhoneEnums.CallsActionsResults.ok,
            data:
              (options.deletedCalls && options.deletedCalls.length > 0) ||
              (options.callsNotes && Object.keys(options.callsNotes).length > 0)
                ? finalLogs.reduce((result, log) => {
                    if (
                      !options.deletedCalls ||
                      options.deletedCalls.indexOf(`${log.id}`) < 0
                    ) {
                      result.push({
                        ...log,
                        notes: options.callsNotes
                          ? options.callsNotes[log.id]
                          : null,
                      });
                    }
                    return result;
                  }, [])
                : finalLogs,
          };
        })
        .catch(() => {
          return { status: PhoneEnums.CallsActionsResults.ko };
        });
    }
    const users =
      filters.searchingUsers ||
      (filters.searchingUser
        ? [filters.searchingUser]
        : [{ username: this.mainUsername, number: this.mainNumber }]);
    const userErrors = [];
    const promises = users.map(
      (user) =>
        new Promise((resolve, reject) => {
          this.command
            .getCdr(user.username, start, end, filterpath, extraFilters)
            .then((response) => {
              logs = logs.concat(response.data.rows);
              logs.sort((a, b) => b.time - a.time);
              if (options.recording) {
                // retrieve all recording calls for this user
                return api.phone
                  .getRecordedCalls()
                  .then((recordResponse) => {
                    if (
                      recordResponse.status < 200 ||
                      recordResponse.status >= 400
                    ) {
                      reject(recordResponse.status);
                    } else {
                      recordingCalls = recordResponse.data;
                      resolve();
                    }
                  })
                  .catch((err) => {
                    Promise.reject(err);
                  });
              }
              Promise.resolve();
            })
            .then(() => resolve())
            .catch(() => {
              userErrors.push(user.number);
              resolve();
            });
        })
    );

    return Promise.all(promises)
      .then(() => {
        if (users.length === userErrors.length) {
          reject();
          return;
        }
        if (!filters.isWakeupCdr) {
          const exec = () => {
            if (filters.in || filters.lost) {
              return this.loadUserGroupsCall(
                users.map((user) => user.number),
                start,
                end
              );
            } else {
              return Promise.resolve([]);
            }
          };
          return exec()
            .then((queueRows) => {
              const finalLogs = this.parseLogsList(
                logs
                  .concat(queueRows)
                  .filter(
                    (row) =>
                      !filters.searchingContacts ||
                      filters.searchingContacts.indexOf(row.src) >= 0 ||
                      filters.searchingContacts.indexOf(row.dst) >= 0
                  )
                  .sort((a, b) => b.time - a.time),
                filters,
                recordingCalls,
                filters.isHotelCdr,
                options && options.customerId
              );
              return {
                status: PhoneEnums.CallsActionsResults.ok,
                userErrors,
                data:
                  (options.deletedCalls && options.deletedCalls.length > 0) ||
                  (options.callsNotes &&
                    Object.keys(options.callsNotes).length > 0)
                    ? finalLogs.reduce((result, log) => {
                        if (
                          !options.deletedCalls ||
                          options.deletedCalls.indexOf(`${log.id}`) < 0
                        ) {
                          result.push({
                            ...log,
                            notes: options.callsNotes
                              ? options.callsNotes[log.id]
                              : null,
                          });
                        }
                        return result;
                      }, [])
                    : finalLogs,
              };
            })
            .catch(() => {
              return { status: PhoneEnums.CallsActionsResults.ko };
            });
        }
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          userErrors,
          data:
            (options.deletedCalls && options.deletedCalls.length > 0) ||
            (options.callsNotes &&
              options.callsNotes &&
              Object.keys(options.callsNotes).length > 0)
              ? logs.reduce((result, log) => {
                  if (
                    !options.deletedCalls ||
                    options.deletedCalls.indexOf(`${log.id}`) < 0
                  ) {
                    result.push({
                      ...log,
                      notes: options.callsNotes
                        ? options.callsNotes[log.id]
                        : null,
                    });
                  }
                  return result;
                }, [])
              : logs,
        };
      })
      .catch(() => {
        return { status: PhoneEnums.CallsActionsResults.ko };
      });
  };

  retrieveQueuesCdr = (filters, options) => {
    const startDate = moment(filters.start).format('YYYYMMDD');
    const endDate = moment(filters.end).format('YYYYMMDD');
    let logs = [];
    const promises = filters.queues.map(
      (queue) =>
        new Promise((resolve, reject) => {
          this.command
            .getCdrSwitchboard(startDate, endDate, queue, true)
            .then((response) => {
              logs = [
                ...logs,
                ...this.parseLogsList(response.data.rows, filters, null, null, options && options.customerId),
              ];
              resolve();
            })
            .catch((err) => reject(err));
        })
    );
    return Promise.all(promises)
      .then(() => {
        logs.sort((a, b) => b.datetime - a.datetime);
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          data:
            options.callsNotes && Object.keys(options.callsNotes).length > 0
              ? logs.map((log) => ({
                  ...log,
                  notes: options.callsNotes ? options.callsNotes[log.id] : null,
                }))
              : logs,
        };
      })
      .catch(() => {
        return { status: PhoneEnums.CallsActionsResults.ko };
      });
  };

  addJanusListener = (eventEmitter) => {
    eventEmitter.on('registration_failed', () => {
      // console.log('registration_failed');
    });
    eventEmitter.on('registered', () => {
      this.webrtcRegistered = true;
      this.activeNumber = this.webrtcNumber;
      // console.log('registered');
    });
    eventEmitter.on('unregistered', () => {
      this.webrtcRegistered = false;
      this.activeNumber = this.mainNumber;
      this.closeWebrtc();
      // console.log('unregistered');
    });
    eventEmitter.on('calling', () => {
      // console.log('calling');
    });
    eventEmitter.on('incomingcall', () => {
      console.log('incomingcall');
    });
    eventEmitter.on('progress', (jsep) => {
      // console.log('progress');
      if (jsep) {
        this.janusCommand.handleRemote(jsep);
      }
    });
    eventEmitter.on('accepted', (jsep) => {
      // console.log('accepted');
      if (jsep) {
        this.janusCommand.handleRemote(jsep);
      }
    });
    eventEmitter.on('hangup', () => {
      // console.log('hangup');
      this.janusCommand.hangup();
    });
    eventEmitter.on('busy', () => {
      // console.log('busy');
      this.janusCommand.hangup();
    });
  };

  activateWebrtcPhone = (eventEmitter) =>
    new Promise((resolve) => {
      if (!this.webrtcNumber || !this.webrtcPassword || !this.webrtcUsername) {
        resolve({ status: PhoneEnums.CallsActionsResults.ko });
        return;
      }
      this.addJanusListener(eventEmitter);
      /* if (this.webrtcStarted) {        
        this.janusCommand.register().then(() =>
          resolve({ status: PhoneEnums.CallsActionsResults.ok })
        );
      } else { */
      this.janusCommand
        .initJanus(eventEmitter)
        .then(() => {
          this.webrtcStarted = true;
          return this.janusCommand.register();
        })
        .then(() => this.command.enableDevice(this.webrtcNumber))
        .then(() => {
          if (this.webrtcInterval) {
            clearInterval(this.webrtcInterval);
          }
          this.webrtcInterval = setInterval(() => {
            if (this.webrtcStarted && !this.janusCommand.isJanusWorking()) {
              clearInterval(this.webrtcInterval);
              eventEmitter.emit('disconnect');
            }
          }, PbxSettings.CHECK_JANUS_ACTIVITY);
          return resolve({ status: PhoneEnums.CallsActionsResults.ok });
        })
        .catch((err) => {
          console.log('Error activating janus', err);
          resolve({ status: PhoneEnums.CallsActionsResults.ko });
        });
      // }
    });

  deactivateWebrtcPhone = () =>
    new Promise((resolve) => {
      if (!this.webrtcRegistered) {
        return resolve({ status: null });
      }
      this.janusCommand
        .unregister()
        .then(() => {
          this.webrtcStarted = false;
          if (this.webrtcInterval) {
            clearInterval(this.webrtcInterval);
          }
          return this.command.enableDevice(this.mainNumber);
        })
        .then(() => resolve({ status: PhoneEnums.CallsActionsResults.ok }));
    });

  closeWebrtc = () => {
    this.janusCommand.closeJanus();
  };

  answerCall = (callData) =>
    this.janusCommand
      .answer(callData)
      .then(() => PhoneEnums.CallsActionsResults.ok)
      .catch((err) => {
        console.log(err);
        return PhoneEnums.CallsActionsResults.ko;
      });

  sendDtmf = (value) =>
    this.janusCommand
      .sendDtmf(value)
      .then(() => PhoneEnums.CallsActionsResults.ok);

  retrievePbxSettings = (params) =>
    api.phoneRules
      .retrievePbxRules(params)
      .then((response) => {
        const error = Utils.checkApiError(response);
        if (error) throw error;
        const rules = response.data || [];
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          rules: rules.map((rule) => this.parseRule(rule)),
        };
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));

  parseRule = (rule) => {
    const startParts = rule.startTime.split(':');
    const endParts = rule.endTime.split(':');
    return {
      timeFromHour: startParts[0],
      timeFromMinute: startParts[1],
      timeToHour: endParts[0],
      timeToMinute: endParts[1],
      days: rule.days,
      startDate: rule.startDate,
      endDate: rule.endDate,
      festivity: rule.festivity,
      action: rule.rule.action,
      event: rule.rule.event,
      defaultCgo: rule.rule.defaultCgo,
      numberCalling: rule.rule.numberCalling,
      forwardCalled:
        rule.rule.forwardCalled === '#' || rule.rule.forwardCalled === '-'
          ? ''
          : rule.rule.forwardCalled,
      enabled: rule.enabled,
      priority: rule.id,
      id: rule.id,
      subject: rule.subject,
      route: rule.route,
      user: rule.user,
      greetingId: rule.rule.greetingId,
    };
  };

  deletePbxSetting = (pbx, rule) => this.deletePhoneRule(rule);

  savePbxSetting = (pbx, rule) => this.savePhoneRule(rule);

  loadOwnVboxGreeting = () =>
    this.command
      .loadOwnVboxGreeting()
      .then((response) => {
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          data: response.data,
        };
      })
      .catch((err) => {
        if (err.status === 404) {
          return {
            status: PhoneEnums.CallsActionsResults.ok,
            data: null,
          };
        }
        return { status: PhoneEnums.CallsActionsResults.ko };
      });
}
